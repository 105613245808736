import React, { useEffect, useState } from 'react'
import { Container, Grid, Tab, Tabs, Typography } from '@mui/material'
import { TabPanel } from 'app/components/tab-panel.component'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/system'
import { PaperGreyComponent } from 'app/components/papers/paper-grey.component'
import { CenterInformationsCard } from 'modules/centers/components/center-informations.component'
import { CenterConfigurationCard } from 'modules/centers/components/center-configuration.component'
import { CenterDetails } from 'api/models'
import { CenterStaffsCard } from 'modules/centers/components/center-staffs.component'
import { CenterListAccesCard } from 'modules/centers/components/center-list-acces.component'
import { CenterGroupAccesCard } from 'modules/centers/components/center-group-acces.component'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useParams } from 'react-router-dom'
import { useFeedback } from 'app/providers/feedback.provider'

const TAB_TYPE = {
  INFORMATIONS: 0,
  CONFIGURATION: 1,
  STAFF: 2,
  ACCESSGROUP: 3,
  ACCESSLIST: 4
}

export const CenterView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const [tab, setTab] = useState(0)
  const { id } = useParams()
  const { getCenter } = useFetcher()
  const { handleMutation } = useFeedback()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [center, setCenter] = useState<CenterDetails>({} as CenterDetails)

  useEffect(() => {
    handleMutation({
      onStart: () => setIsLoading(true),
      mutation: getCenter,
      data: id,
      onSuccess: (data) => setCenter(data),
      onEnd: () => setIsLoading(false)
    }).then()
  }, [])

  const handleChangeTab = async (_: React.SyntheticEvent, newValue: number) => {
    setTab(newValue)
  }

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Typography variant="h1">
              {t('center_sheet')} | {center?.name}{' '}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Typography variant="body2">{center?.reference}</Typography>
      <PaperGreyComponent>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          aria-label="company_tabs"
          variant="scrollable"
          scrollButtons="auto"
          sx={{ marginBottom: 6 }}
        >
          <Tab label={t('informations')} value={TAB_TYPE.INFORMATIONS} />
          <Tab
            label={t('configuration')}
            iconPosition={'end'}
            value={TAB_TYPE.CONFIGURATION}
            disabled={isLoading}
          />
          <Tab
            label={t('staff_word')}
            iconPosition={'end'}
            value={TAB_TYPE.STAFF}
            disabled={isLoading}
          />
          <Tab
            label={t('access_group')}
            iconPosition={'end'}
            value={TAB_TYPE.ACCESSGROUP}
            disabled={isLoading}
          />
          <Tab
            label={t('access_list')}
            iconPosition={'end'}
            value={TAB_TYPE.ACCESSLIST}
            disabled={isLoading}
          />
        </Tabs>
      </PaperGreyComponent>
      <TabPanel value={tab} index={TAB_TYPE.INFORMATIONS}>
        <CenterInformationsCard center={center} isLoading={isLoading}></CenterInformationsCard>
      </TabPanel>
      <TabPanel value={tab} index={TAB_TYPE.CONFIGURATION}>
        <CenterConfigurationCard center={center} isLoading={isLoading}></CenterConfigurationCard>
      </TabPanel>
      <TabPanel value={tab} index={TAB_TYPE.STAFF}>
        <CenterStaffsCard center={center}></CenterStaffsCard>
      </TabPanel>
      <TabPanel value={tab} index={TAB_TYPE.ACCESSGROUP}>
        <CenterGroupAccesCard center={center}></CenterGroupAccesCard>
      </TabPanel>
      <TabPanel value={tab} index={TAB_TYPE.ACCESSLIST}>
        <CenterListAccesCard center={center}></CenterListAccesCard>
      </TabPanel>
    </Container>
  )
}
