import { z } from 'zod'
import { individualClientsListSchema } from './individuals'

export const enterpriseSchema = z.object({
  id: z.string(),
  reference: z.string(),
  name: z.string(),
  city: z.string(),
  createdAt: z.string(),
  status: z.string(),
  centerId: z.string(),
  centerName: z.string()
})

export type EnterpriseType = z.infer<typeof enterpriseSchema>

export const enterprisesListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(enterpriseSchema)
})

export type EnterprisesListType = z.infer<typeof enterprisesListSchema>
const enterpriseMemberSchema = z.object({
  individualId: z.string(),
  id: z.string(),
  reference: z.string(),
  firstname: z.string(),
  lastname: z.string(),
  center: z.string(),
  role: z.string(),
  isPresent: z.boolean(),
  job: z.string()
})

export type Member = z.infer<typeof enterpriseMemberSchema>

export const enterpriseMembersListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: individualClientsListSchema
})
export type EnterpriseMembersList = z.infer<typeof enterpriseMembersListSchema>

const enterpriseOpportunitySchema = z.object({
  id: z.number(),
  reference: z.string(),
  dueDate: z.string(),
  city: z.string(),
  status: z.string(),
  statusLabel: z.string()
})

export type EnterpriseOpportunities = z.infer<typeof enterpriseOpportunitySchema>

export const enterpriseOpportunitiesListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(enterpriseOpportunitySchema)
})
export type EnterpriseOpportunitiesList = z.infer<typeof enterpriseOpportunitiesListSchema>

const enterpriseContractSchema = z.object({
  id: z.number(),
  reference: z.string(),
  center: z.number(),
  centerName: z.string(),
  remainingMonths: z.number(),
  mainPrice: z.number(),
  optionsPrice: z.number(),
  surface: z.number(),
  state: z.string(),
  contractType: z.string(),
  contractTypeLabel: z.string(),
  stateLabel: z.string()
})

export const enterpriseContractsListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(enterpriseContractSchema)
})
export type EnterpriseContractsList = z.infer<typeof enterpriseContractsListSchema>

export type EnterpriseContract = z.infer<typeof enterpriseContractSchema>

const organisationConsumptionSchema = z.object({
  id: z.number(),
  reference: z.string(),
  center: z.string()
})

export const enterpriseInformationSchema = z.object({
  id: z.number(),
  name: z.string(),
  centerName: z.string(),
  mainCenter: z.number(),
  workforce: z.number(),
  address: z.string(),
  city: z.string(),
  zipCode: z.string(),
  country: z.string(),
  accountingContact: z.string(),
  isKeyAccounting: z.boolean(),
  language: z.string(),
  languageId: z.number(),
  nbDocument: z.number(),
  nbContract: z.number(),
  contracts: z.array(enterpriseContractSchema),
  consumptions: z.array(organisationConsumptionSchema),
  nbMember: z.number(),
  nbOpportunities: z.number(),
  isDuplicate: z.boolean(),
  reference: z.string(),
  type: z.string(),
  website: z.string(),
  ca: z.number(),
  mobile: z.string(),
  phone: z.string(),
  email: z.string().email(),
  vat: z.string(),
  rcs: z.string(),
  rcsCity: z.string(),
  siret: z.string(),
  status: z.number(),
  ape: z.string(),
  prescriber: z.number().nullable(),
  dueOffset: z.number()
})

export const enterpriseInformationFormSchema = z.object({
  id: z.number(),
  accountingContact: z.string().email().optional().or(z.literal('')).nullable(),
  address: z.string().nullable().optional(),
  ape: z.string().nullable().optional(),
  ca: z.coerce.number().nullable().optional(),
  city: z.string().nullable().optional(),
  country: z.string().nullable().optional(),
  dueOffset: z.coerce.number().nullable().optional(),
  email: z.string().email().optional().or(z.literal('')).nullable(),
  isKeyAccount: z.boolean().nullable().optional(),
  isKeyAccounting: z.boolean().nullable().optional(),
  languageId: z.coerce.number().nullable().optional(),
  mainCenter: z.coerce.number().nullable().optional(),
  mobile: z.string().nullable().optional(),
  name: z.string().min(2),
  phone: z.string().nullable().optional(),
  prescriber: z.coerce.number().nullable().optional(),
  rcs: z.string().nullable().optional(),
  rcsCity: z.string().nullable().optional(),
  siret: z.string().nullable().optional(),
  status: z.coerce.number().nullable().optional(),
  type: z.string().nullable().optional(),
  vat: z.string().nullable().optional(),
  website: z.string().nullable().optional(),
  workforce: z.number({ coerce: true }).nullable().optional(),
  zipcode: z.string().nullable().optional()
})

export type EnterpriseInformation = z.infer<typeof enterpriseInformationSchema>

const enterpriseDocumentSchema = z.object({
  id: z.number(),
  name: z.string(),
  type: z.string(),
  status: z.number(),
  statusLabel: z.string(),
  createdAt: z.string(),
  updatedAt: z.string()
})

export type EnterpriseDocuments = z.infer<typeof enterpriseDocumentSchema>

const enterpriseDocumentsListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(enterpriseDocumentSchema)
})

export type EnterpriseDocumentsList = z.infer<typeof enterpriseDocumentsListSchema>

const enterpriseDocumentsListParamsSchema = z.object({
  id: z.string(),
  invalidated: z.number()
})
export type EnterpriseDocumentsListParams = z.infer<typeof enterpriseDocumentsListParamsSchema>

export const duplicateEnterpriseSchema = z.object({
  id: z.string(),
  name: z.string(),
  reference: z.string(),
  siret: z.string(),
  id2: z.string(),
  name2: z.string(),
  reference2: z.string(),
  siret2: z.string()
})

export type DuplicateEnterpriseType = z.infer<typeof duplicateEnterpriseSchema>

export const duplicateEnterprisesListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(duplicateEnterpriseSchema)
})

export type DuplicateEnterprisesList = z.infer<typeof duplicateEnterprisesListSchema>

export type IdReturn = { id: string }
