import { z } from 'zod'

const paymentSchema = z.object({
  id: z.number(),
  clientId: z.number(),
  center: z.string(),
  reference: z.string(),
  clientType: z.string(),
  state: z.number(),
  paymentType: z.number(),
  paymentMode: z.number(),
  stateLabel: z.string(),
  paymentModeLabel: z.string(),
  paymentTypeLabel: z.string(),
  client: z.string(),
  accountingDocument: z.string(),
  accountingDocumentType: z.string(),
  accountingDocumentId: z.number(),
  contract: z.string(),
  contractId: z.number(),
  created: z.string(),
  paid: z.string(),
  amount: z.number()
})

export type Payment = z.infer<typeof paymentSchema>

const paymentsListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(paymentSchema)
})

export type PaymentsList = z.infer<typeof paymentsListSchema>

const paymentsSumSchema = z.object({
  totalAmount: z.number()
})

export type PaymentsSum = z.infer<typeof paymentsSumSchema>
