import React, { RefObject } from 'react'
import { Dialog, DialogRef } from 'app/components/dialog/dialog.component'
import { useTranslation } from 'react-i18next'
import { Button, Grid, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useFetcher } from 'app/providers/fetcher.provider'
import { zodResolver } from '@hookform/resolvers/zod'
import { useFeedback } from 'app/providers/feedback.provider'
import { useNavigate } from 'react-router-dom'
import { TermOfSalesItem, UploadTermOfSalesInput, uploadTermOfSalesInput } from 'api/models'
import { UploadButton } from 'app/components/form/uploadbutton.component'
import { ControlledDatePicker } from 'app/components/form/controlled-datepicker.component'
import dayjs from 'dayjs'

interface IDialogEditAcquisitionCost {
  dialogRef: RefObject<DialogRef>
  termOfSales: TermOfSalesItem | null
}

export const DialogEditTermOfSales = function ({
  dialogRef,
  termOfSales
}: IDialogEditAcquisitionCost) {
  const { t } = useTranslation()
  const { uploadTermOfSales, updateTermOfSales } = useFetcher()
  const { handleMutation, confirm } = useFeedback()
  const navigate = useNavigate()
  const method = useForm<UploadTermOfSalesInput>({
    mode: 'onChange',
    resolver: zodResolver(uploadTermOfSalesInput)
  })
  const { isValid, isSubmitting } = method.formState

  const handleConfirm = async (data: UploadTermOfSalesInput) => {
    if (termOfSales) {
      await handleMutation({
        mutation: updateTermOfSales,
        data: {
          id: termOfSales.id,
          data: {
            begin: dayjs(termOfSales.begin),
            end: data.begin.clone().endOf('day').subtract(1, 'day')
          }
        }
      })
    }

    await handleMutation({
      mutation: uploadTermOfSales,
      data: data,
      onSuccess: () => {
        dialogRef.current?.close()
        navigate(0)
      }
    })
  }

  const handleSubmit = async (data: UploadTermOfSalesInput) => {
    confirm({
      content: t('confirm_add_terms_of_sales'),
      onConfirm: async () => {
        await handleConfirm(data)
      }
    })
  }

  return (
    <Dialog
      ref={dialogRef}
      title={t('add_terms_of_sales')}
      actions={
        <>
          <Button variant={'outlined'} onClick={dialogRef.current?.close}>
            {t('cancel')}
          </Button>
          <Button
            variant={'contained'}
            disabled={!isValid || isSubmitting}
            onClick={method.handleSubmit(handleSubmit)}
          >
            {t('update')}
          </Button>
        </>
      }
    >
      <Grid container spacing={4} marginY={2} columns={12} alignItems={'center'}>
        <Grid item xs={2}>
          <Typography fontWeight={'bold'}>{t('PDF')}</Typography>
        </Grid>
        <Grid item xs={10}>
          <UploadButton
            name={'file'}
            multiple={false}
            accept={'application/pdf'}
            onChange={(file) => {
              method.setValue('file', file as File, { shouldValidate: true })
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={4} marginY={2} columns={12} alignItems={'center'}>
        <Grid item xs={2}>
          <Typography fontWeight={'bold'}>{t('begin')}</Typography>
        </Grid>
        <Grid item xs={10}>
          <ControlledDatePicker
            control={method.control}
            required={true}
            name={'begin'}
            minDate={termOfSales && termOfSales.begin ? dayjs(termOfSales.begin) : undefined}
          />
        </Grid>
      </Grid>
    </Dialog>
  )
}
