import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from '@mui/system'
import { Box, Button, Grid, IconButton, Typography } from '@mui/material'
import { List } from 'app/components/lists/list'
import { FiltersBox } from 'app/components/filters/filter-box'
import { SearchCenterInput } from 'app/components/filters/search-center-input'
import { ListPagination } from 'app/components/lists/list-pagination'
import { formatCurrency, formatDate } from 'app/utils/format'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useList } from 'app/providers/list.provider'
import { AcquisitionCost } from 'api/models/sources'
import { MonthPicker } from 'app/components/filters/month-picker'
import { useFeedback } from 'app/providers/feedback.provider'
import { PaperGreyComponent } from 'app/components/papers/paper-grey.component'
import { UploadButton } from 'app/components/form/uploadbutton.component'
import { Add, Remove } from '@mui/icons-material'
import { useApp } from 'app/providers/app.provider'
import { DialogEditAcquisitionCost } from 'modules/sources/components/dialog-edit-acquisition-cost'
import { DialogRef } from 'app/components/dialog/dialog.component'

export const AcquisitionCostView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const {
    filtersList,
    isLast,
    total,
    orderBy,
    setOffset,
    setTotal,
    setIsLast,
    initFilters,
    handleSort,
    handleFilter
  } = useList()
  const { getAcquisitionsCost, uploadAcquisitionCostsCSV, searchParams, setSearchParams } =
    useFetcher()
  const [acquisitionCosts, setAcquisitionCosts] = useState<AcquisitionCost[]>([])
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([
      ['acquisition_cost_canal', 'canal'],
      ['acquisition_cost_subcanal', 'subcanal'],
      ['main_services_types', 'type']
    ])
  )
  const { toast } = useFeedback()
  const [file, setFile] = useState<File | FileList | null>(null)
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const [uploadOpen, setUploadOpen] = useState<boolean>(false)
  const { getRight } = useApp()
  const dialogRef = useRef<DialogRef>(null)
  const [selectedAcquisition, setSelectedAcquisition] = useState<AcquisitionCost | null>(null)

  const refreshList = useCallback(
    async (reset: boolean) => {
      setListIsLoading(true)
      try {
        const data = await getAcquisitionsCost.mutateAsync()
        setOffset(data.actual)
        setIsLast(data.last)
        setTotal(data.total)
        setAcquisitionCosts(reset ? data.items : (prev) => [...prev, ...data.items])
      } finally {
        setListIsLoading(false)
      }
    },
    [getAcquisitionsCost, setIsLast, setOffset, setTotal]
  )

  const handleFile = (file: File | FileList | null) => {
    setFile(file)
  }

  const onSubmit = async () => {
    if (!file) return
    try {
      await uploadAcquisitionCostsCSV.mutateAsync(file)
      await refreshList(true).then()
      toast({
        message: t('upload_success'),
        variant: 'success'
      })
    } catch (err: any) {
      toast({
        message: t(err.message),
        variant: 'error'
      })
    }
  }
  useEffect(() => {
    initFilters(commonFilters).then(() => refreshList(true))
  }, [])

  const toggleEdition = () => setUploadOpen(!uploadOpen)

  const handleClickRow = (data: AcquisitionCost) => {
    setSelectedAcquisition(data)
    dialogRef.current?.open()
  }

  return (
    <Container>
      <DialogEditAcquisitionCost dialogRef={dialogRef} acquisitionCost={selectedAcquisition} />
      <Box marginBottom="1rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('acquisition_costs')}
        </Typography>
      </Box>
      <Grid>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => handleFilter(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <SearchCenterInput
            slug="center"
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            byPassAllCenters={true}
          />
          <Grid sx={{ width: 200 }}>
            <MonthPicker
              slug={'day'}
              label={t('date')}
              onChange={() => handleFilter(refreshList, true)}
              setSearchParams={setSearchParams}
              searchParams={searchParams}
              views={['year', 'month', 'day']}
              format={'YYYY-MM-DD'}
              clearable={true}
            />
          </Grid>
        </FiltersBox>
      </Grid>
      {getRight('acquisition_cost', 'isAdd') && (
        <Grid container columns={12}>
          <Grid item xs={12}>
            <PaperGreyComponent sx={{ paddingLeft: 4, paddingY: 4 }}>
              <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={2}>
                <Typography>{t('send_csv')}</Typography>
                <IconButton onClick={toggleEdition} size={'small'}>
                  {uploadOpen ? (
                    <Remove style={{ fontSize: '12px' }} />
                  ) : (
                    <Add style={{ fontSize: '12px' }} />
                  )}
                </IconButton>
              </Box>
              {uploadOpen && (
                <Grid container columns={12} columnSpacing={2} marginTop={4} textAlign={'center'}>
                  <Grid item xs={5} alignItems={'center'}>
                    <UploadButton
                      name={'file'}
                      onChange={handleFile}
                      accept={'text/csv, text/plain'}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Button
                      color="secondary"
                      fullWidth
                      variant="contained"
                      disabled={!file}
                      onClick={onSubmit}
                    >
                      {t('send')}
                    </Button>
                  </Grid>
                </Grid>
              )}
            </PaperGreyComponent>
          </Grid>
        </Grid>
      )}
      <Grid>
        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {acquisitionCosts.length} / {total}
          </Typography>
        </Grid>
        <List
          items={acquisitionCosts}
          onClick={handleClickRow}
          columns={[
            {
              label: t('center'),
              slug: 'center'
            },
            {
              label: t('type'),
              slug: 'type'
            },
            {
              label: t('canal'),
              slug: 'canal'
            },
            {
              label: t('subcanal'),
              slug: 'subcanal'
            },
            {
              label: t('source'),
              slug: 'source'
            },
            {
              label: t('date'),
              slug: 'day',
              valueFormatter: formatDate
            },
            {
              label: t('cost'),
              slug: 'cost',
              valueFormatter: formatCurrency
            }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(() => refreshList(true), property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {acquisitionCosts.length} / {total}
          </Typography>
        </Grid>
        {!isLast && <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />}
      </Grid>
    </Container>
  )
}
