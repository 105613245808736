import React from 'react'
import { useTranslation } from 'react-i18next'
import { centerUpdateInfoInput } from 'api/models'
import { CenterUpdateContainer } from 'modules/centers/components/center-update-container.component'

export const CenterInformationEditView = (): React.JSX.Element => {
  const { t } = useTranslation()
  return (
    <CenterUpdateContainer
      title={'informations'}
      items={[
        {
          type: 'textfield',
          label: t('center_trade_name'),
          name: 'companyName',
          required: true
        },
        {
          type: 'datepicker',
          label: t('center_opening'),
          name: 'openingDate'
        },
        {
          type: 'textfield',
          label: t('center_program_name'),
          name: 'realEstateProgram'
        },
        {
          type: 'number',
          label: t('surface'),
          name: 'rentalSurface',
          inputProps: {
            min: 0,
            step: 1
          }
        },
        {
          type: 'datepicker',
          label: t('center_lease_signing_date'),
          name: 'leaseSigningDate'
        },
        {
          type: 'datepicker',
          label: t('center_date_MADA'),
          name: 'madaDate'
        }
      ]}
      resolver={centerUpdateInfoInput}
    />
  )
}
