import React, { useCallback, useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'

import { Prescriber } from 'api/models'
import { List } from 'app/components/lists/list'
import { useFetcher } from 'app/providers/fetcher.provider'
import { ListPagination } from 'app/components/lists/list-pagination'
import { FiltersBox } from 'app/components/filters/filter-box'
import { useList } from 'app/providers/list.provider'
import { SearchInput } from 'app/components/filters/search-input'

export const PrescribersView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const {
    filtersList,
    isLast,
    total,
    orderBy,
    setOffset,
    setTotal,
    setIsLast,
    initFilters,
    handleSort,
    handleFilter
  } = useList()
  const { getPrescribers, searchParams, setSearchParams } = useFetcher()
  const [prescribers, setPrescribers] = useState<Prescriber[]>([])
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([['prescribers_type', 'types']])
  )

  const [listIsLoading, setListIsLoading] = useState<boolean>(true)

  const refreshList = useCallback(
    async (reset: boolean = true) => {
      setListIsLoading(true)
      await getPrescribers
        .mutateAsync()
        .then((data) => {
          setOffset(data.actual)
          setIsLast(data.last)
          setTotal(data.total)
          setPrescribers(reset ? data.items : (prev) => [...prev, ...data.items])
        })
        .finally(() => setListIsLoading(false))
    },
    [getPrescribers, setIsLast, setOffset, setTotal]
  )

  useEffect(() => {
    initFilters(commonFilters).then(() => refreshList(true))
  }, [])

  return (
    <Container>
      <Box marginBottom="1rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('prescribers_list')}
        </Typography>
      </Box>
      <Grid>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => handleFilter(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <SearchInput
            slug={'enterprise_name'}
            label={'enterprise'}
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <SearchInput
            slug={'individual_name'}
            label={'name'}
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <SearchInput
            slug={'city'}
            label={'city'}
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
        </FiltersBox>
      </Grid>
      <Grid>
        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {prescribers.length} / {total}
          </Typography>
        </Grid>
        <List
          items={prescribers}
          columns={[
            {
              label: t('name'),
              slug: 'individualName',
              link: { base: '/individuals', slug: 'individualId' }
            },
            {
              label: t('enterprise'),
              slug: 'enterpriseName',
              link: { base: '/enterprises', slug: 'enterpriseId' }
            },
            { label: t('city'), slug: 'city' },
            { label: t('label'), slug: 'typeLabel' },
            { label: t('mobile'), slug: 'mobile' },
            { label: t('email'), slug: 'email' }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(refreshList, property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {prescribers.length} / {total}
          </Typography>
        </Grid>
        {!isLast && <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />}
      </Grid>
    </Container>
  )
}
