import React, { useCallback, useEffect, useState } from 'react'
import { Container } from '@mui/system'
import { Box, Grid, Typography } from '@mui/material'
import { List } from 'app/components/lists/list'
import { ListPagination } from 'app/components/lists/list-pagination'
import { useList } from 'app/providers/list.provider'
import { useFetcher } from 'app/providers/fetcher.provider'
import { CenterType } from 'api/models'
import { useTranslation } from 'react-i18next'
import { useFeedback } from 'app/providers/feedback.provider'

export const CentersView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { isLast, total, orderBy, setOffset, setTotal, setIsLast, handleSort, handleFilter } =
    useList()
  const { getCenters } = useFetcher()
  const [centers, setCenters] = useState<CenterType[]>([])
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const { handleMutation } = useFeedback()

  const refreshList = useCallback(async (reset: boolean) => {
    await handleMutation({
      onStart: () => setListIsLoading(true),
      mutation: getCenters,
      onSuccess: (data) => {
        setOffset(data.actual)
        setIsLast(data.last)
        setTotal(data.total)
        setCenters(reset ? data.items : (prev) => [...prev, ...data.items])
      },
      onEnd: () => setListIsLoading(false)
    })
  }, [])

  useEffect(() => {
    refreshList(true).then()
  }, [])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('centers_list')}
        </Typography>
      </Box>
      <Grid>
        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {centers.length} / {total}
          </Typography>
        </Grid>
        <List
          items={centers}
          columns={[
            { label: t('reference'), slug: 'reference', link: { base: '/centers', slug: 'id' } },
            { label: t('name'), slug: 'name', link: { base: '/centers', slug: 'id' } },
            { label: t('phone'), slug: 'phone' },
            { label: t('email'), slug: 'email' },
            { label: t('cluster'), slug: 'clusterName' },
            { label: t('society'), slug: 'companyCode' }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(() => refreshList(true), property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {centers.length} / {total}
          </Typography>
        </Grid>
        {!isLast && <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />}
      </Grid>
    </Container>
  )
}
