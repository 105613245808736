import { MouseEvent } from 'react'
import { Button, Grid } from '@mui/material'

interface IListPaginationProps {
  handleRedirect: (reset: boolean) => void
}

export function ListPagination({ handleRedirect }: IListPaginationProps): JSX.Element {
  return (
    <Grid container alignContent="center" justifyContent="center" marginY={2}>
      <Button type={'button'} variant="outlined" onClick={() => handleRedirect(false)}>
        Voir plus
      </Button>
    </Grid>
  )
}
