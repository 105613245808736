import React, { useEffect, useMemo, useState } from 'react'

import { Box, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Container } from '@mui/system'
import { FiltersBox } from 'app/components/filters/filter-box'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useList } from 'app/providers/list.provider'
import { useApp } from 'app/providers/app.provider'
import { SurfaceChart } from 'modules/stats/components/surface-chart.component'
import { RangePicker } from 'app/components/filters/range-picker'

export const StatsSurfaceView = () => {
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([['centers', 'centers']])
  )

  const { t, i18n } = useTranslation()
  const { filtersList, initFilters } = useList()
  const { searchParams, setSearchParams } = useFetcher()
  const { user } = useApp()
  const paramsFilters = useMemo(() => {
    return {
      centers: {
        default: user?.mainCenter
      }
    }
  }, [user])

  useEffect(() => {
    initFilters(commonFilters, paramsFilters).then()
  }, [i18n.language])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('stats_price_m2')}
        </Typography>
      </Box>
      <Grid>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => {}}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <RangePicker
            slug="date"
            label={t('Date')}
            slugs={{ begin: 'date_begin', end: 'date_end' }}
            onChange={() => () => {}}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            isNoDate={false}
            valueLabel={'Les 12 derniers mois'}
          />
        </FiltersBox>
      </Grid>
      <SurfaceChart height={350} params={searchParams} />
    </Container>
  )
}
