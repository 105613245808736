import { useTranslation } from 'react-i18next'
import {
  Card,
  CardContent,
  CardMedia,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme
} from '@mui/material'
import DefaultImage from 'app/assets/default-center.jpg'
import Avatar from '@mui/material/Avatar'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom'
import React from 'react'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import { CenterDetails } from 'api/models'
import { formatDateWithFormat } from 'app/utils/format'
import dayjs from 'dayjs'

interface ICenterDisplayCardInfoConfigurationProps {
  center: CenterDetails
}

export const CenterDisplayCardInfoConfiguration = ({
  center
}: ICenterDisplayCardInfoConfigurationProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  return (
    <Card
      sx={{
        [theme.breakpoints.down('lg')]: {
          display: 'flex'
        }
      }}
    >
      <CardMedia
        component="img"
        alt="center"
        image={center?.centerImage ? center.centerImage : DefaultImage}
        sx={{
          height: 230,
          [theme.breakpoints.down('lg')]: {
            width: 151,
            height: 'auto'
          }
        }}
      />
      <CardContent>
        <List>
          <ListItem disableGutters>
            <ListItemIcon>
              <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                <MeetingRoomIcon />
              </Avatar>
            </ListItemIcon>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography sx={{ display: 'inline' }} component="span" variant="body2">
                    {t('opening_day')} :
                  </Typography>
                  <Typography
                    sx={{
                      display: 'inline',
                      paddingLeft: 1,
                      [theme.breakpoints.down('lg')]: {
                        display: 'block',
                        paddingLeft: 0
                      }
                    }}
                    component="span"
                    variant="body2"
                    color="primary"
                  >
                    {center.centerOpeningDays
                      ?.map((dayObj: { openingDay: number }) =>
                        dayjs().day(dayObj.openingDay).format('dddd')
                      )
                      .join(', ')}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          <ListItem disableGutters>
            <ListItemIcon>
              <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                <AccessTimeIcon />
              </Avatar>
            </ListItemIcon>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography sx={{ display: 'inline' }} component="span" variant="body2">
                    {t('opening_hours')} :
                  </Typography>
                  <Typography
                    sx={{
                      display: 'inline',
                      paddingLeft: 1,
                      [theme.breakpoints.down('lg')]: {
                        display: 'block',
                        paddingLeft: 0
                      }
                    }}
                    component="span"
                    variant="body2"
                    color="primary"
                  >
                    {formatDateWithFormat(center.openingHour ?? '', 'LT')} -{' '}
                    {formatDateWithFormat(center.closureHour ?? '', 'LT')}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          <ListItem disableGutters>
            <ListItemIcon>
              <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                <PhoneAndroidIcon />
              </Avatar>
            </ListItemIcon>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography sx={{ display: 'inline' }} component="span" variant="body2">
                    {t('phone')} :
                  </Typography>
                  <Typography
                    sx={{
                      display: 'inline',
                      paddingLeft: 1,
                      [theme.breakpoints.down('lg')]: {
                        display: 'block',
                        paddingLeft: 0
                      }
                    }}
                    component="span"
                    variant="body2"
                    color="primary"
                  >
                    {center.phone}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          <ListItem disableGutters>
            <ListItemIcon>
              <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                <MailOutlineIcon />
              </Avatar>
            </ListItemIcon>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography sx={{ display: 'inline' }} component="span" variant="body2">
                    {t('email')} :
                  </Typography>
                  <Typography
                    sx={{
                      display: 'inline',
                      paddingLeft: 1,
                      [theme.breakpoints.down('lg')]: {
                        display: 'block',
                        paddingLeft: 0
                      }
                    }}
                    component="span"
                    variant="body2"
                    color="primary"
                  >
                    {center.email}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  )
}
