import React, { useCallback, useEffect, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useNavigate, useParams } from 'react-router-dom'
import {
  MainService,
  ParkingServiceBody,
  parkingServiceBodySchema,
  FormItemValue
} from 'api/models'
import { Container, Grid, Typography, Paper as MuiPaper, Button, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { Stack } from '@mui/system'
import { KeyboardBackspace } from '@mui/icons-material'
import { Link } from 'app/components/link.component'
import { useFeedback } from 'app/providers/feedback.provider'
import LanguageComponent from 'modules/services/components/languages.component'
import ParkingMainInformationsComponent from 'modules/services/components/parking-main-informations.component'
import dayjs from 'dayjs'
import { RectangularSkeleton } from 'app/components/skeletons/rectangular.skeleton'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

export const ParkingServiceEditView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { getParkingService, getFilters, updateParkingService } = useFetcher()
  const { id } = useParams()
  const [mainService, setMainService] = useState<MainService>({} as MainService)
  const [typologies, setTypologies] = useState<FormItemValue[]>([])
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const { handleMutation } = useFeedback()
  const navigate = useNavigate()
  const methods = useForm<ParkingServiceBody>({
    mode: 'onChange',
    resolver: zodResolver(
      parkingServiceBodySchema.refine((data) => {
        if (!data.savedLabels) return false
        const fr = data.savedLabels.find((sl) => sl.language === 1)
        return fr && fr.label && data.begin && data.price
      })
    )
  })
  methods.watch(['typology', 'price'])

  useEffect(() => {
    getData().then()
  }, [])

  useEffect(() => {
    methods.setValue('begin', dayjs.utc(mainService.begin))
    methods.setValue('end', mainService.end ? dayjs.utc(mainService.end) : null)
    methods.setValue('typology', mainService.typologyId)
  }, [mainService])

  const getData = useCallback(async () => {
    setListIsLoading(true)
    await handleMutation({
      mutation: getParkingService,
      data: Number(id),
      onSuccess: (response) => {
        setMainService(response)
      }
    })
    await handleMutation({
      mutation: getFilters,
      data: { variables: ['parking_services_typologies'], params: {} },
      onSuccess: (data) => {
        if (data && data.parking_services_typologies) {
          setTypologies(data.parking_services_typologies.values)
        }
      }
    })
    setListIsLoading(false)
  }, [getParkingService, id, getFilters])

  const handleSubmit = useCallback(
    async (data: ParkingServiceBody) => {
      await handleMutation({
        confirm: {
          content: t('confirm_edit_parking')
        },
        mutation: updateParkingService,
        data: { id: Number(id), data: data },
        onSuccess: () => navigate(`/services/parking/${id}`)
      })
    },
    [updateParkingService, id]
  )

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Link to={`/services/parking/${id}`} style={{ lineHeight: '1em' }}>
              <KeyboardBackspace fontSize={'small'} />
            </Link>
            <Typography variant="h1">{t('parking_edit')}</Typography>
          </Stack>
        </Grid>
      </Grid>
      <form onSubmit={methods.handleSubmit(handleSubmit)} autoComplete="off">
        <MuiPaper sx={{ marginTop: 4, padding: 5, marginBottom: 5 }}>
          {listIsLoading ? (
            <CardSkeleton />
          ) : (
            <LanguageComponent control={methods.control} service={mainService} />
          )}
        </MuiPaper>
        <MuiPaper sx={{ padding: 5, marginBottom: 5 }}>
          {listIsLoading ? (
            <CardSkeleton />
          ) : (
            <ParkingMainInformationsComponent
              methods={methods}
              mainService={mainService}
              typologies={typologies}
            />
          )}
        </MuiPaper>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 5, marginTop: 5 }}>
          {listIsLoading ? (
            <RectangularSkeleton />
          ) : (
            <>
              <Button variant={'outlined'} onClick={() => navigate(`/services/parking/${id}`)}>
                {t('cancel')}
              </Button>
              <Button
                type={'submit'}
                variant="contained"
                size="small"
                disabled={!methods.formState.isValid}
              >
                {t('edit')}
              </Button>
            </>
          )}
        </Box>
      </form>
    </Container>
  )
}
