import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from '@mui/system'
import { Box, Grid, Typography } from '@mui/material'

import { List } from 'app/components/lists/list'
import { FiltersBox } from 'app/components/filters/filter-box'
import { SearchCenterInput } from 'app/components/filters/search-center-input'
import { ListPagination } from 'app/components/lists/list-pagination'
import type { Indexing } from 'api/models'
import { useFetcher } from 'app/providers/fetcher.provider'
import { MonthPicker } from 'app/components/filters/month-picker'
import { useList } from 'app/providers/list.provider'
import {
  formatContractState,
  formatCurrency,
  formatDate,
  formatMonth,
  formatRate
} from 'app/utils/format'

export const IndexingView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const {
    filtersList,
    isLast,
    total,
    orderBy,
    setOffset,
    setTotal,
    setIsLast,
    initFilters,
    handleSort,
    handleFilter
  } = useList()
  const { getIndexing, searchParams, setSearchParams } = useFetcher()
  const [indexing, setIndexing] = useState<Indexing[]>([])
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([['indexation_types', 'indexation_type']])
  )
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)

  const refreshList = useCallback(
    async (reset: boolean) => {
      setListIsLoading(true)
      getIndexing
        .mutateAsync()
        .then((data) => {
          setOffset(data.actual)
          setIsLast(data.last)
          setTotal(data.total)
          setIndexing(reset ? data.items : (prev) => [...prev, ...data.items])
        })
        .finally(() => setListIsLoading(false))
    },
    [getIndexing, setIsLast, setOffset, setTotal]
  )

  useEffect(() => {
    initFilters(commonFilters).then(() => refreshList(true))
  }, [])

  return (
    <Container>
      <Box marginBottom="1rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('indexing_list')}
        </Typography>
      </Box>
      <Grid>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => handleFilter(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <SearchCenterInput
            slug="center"
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            byPassAllCenters={true}
          />
          <MonthPicker
            slug="indexing_date"
            label={'indexing_date'}
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
        </FiltersBox>
      </Grid>
      <Grid>
        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {indexing.length} / {total}
          </Typography>
        </Grid>
        <List
          items={indexing}
          columns={[
            {
              label: t('state'),
              slug: 'contractState',
              text: 'contractStateLabel',
              valueFormatter: formatContractState
            },
            {
              label: t('contract_reference'),
              slug: 'contractReference',
              link: { base: '/contracts', slug: 'contractId' }
            },
            {
              label: t('client'),
              slug: 'clientName',
              link: { base: '/enterprises', slug: 'clientId' }
            },
            { label: t('center'), slug: 'centerName' },
            { label: t('old_price'), slug: 'oldPrice', valueFormatter: formatCurrency },
            { label: t('new_price'), slug: 'newPrice', valueFormatter: formatCurrency },
            { label: t('rate'), slug: 'indexingRate', valueFormatter: formatRate },
            { label: t('indexed_at'), slug: 'indexedAt', valueFormatter: formatDate },
            { label: t('next_indexation'), slug: 'nextIndexation', valueFormatter: formatMonth }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(() => refreshList(true), property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {indexing.length} / {total}
          </Typography>
        </Grid>
        {!isLast && <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />}
      </Grid>
    </Container>
  )
}
