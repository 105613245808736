import {
  authRouter,
  activitiesRouter,
  companiesRouter,
  enterprisesRouter,
  contractsRouter,
  contactsRouter,
  filtersRouter,
  invoicesRouter,
  occupancyRouter,
  optionsRouter,
  litigationsRouter,
  opportunitiesRouter,
  individualsRouter,
  checkoutsRouter,
  rightsRouter,
  mandatesRouter,
  servicesRouter,
  prescribersRouter,
  paymentsRouter,
  quotationsRouter,
  creditsRouter,
  consumptionsRouter,
  duplicateEnterprisesRouter,
  indexingRouter,
  endorsementsRouter,
  clientCenterRouter,
  clientRequestsRouter,
  customerReservationsRouter,
  accountingRouter,
  eventsRouter,
  happeningsRouter,
  formItemsRouter,
  floorPlansRouter,
  planningsRouter,
  dashboardRouter,
  statsRouter,
  pricerRouter,
  consumptionContractsRouter,
  documentsRouter,
  centersRouter,
  sourcesRouter,
  wifiRouter,
  discountGridRouter,
  discountGridCenterRouter,
  staffsRouter,
  termsOfSalesRouter,
  tertiariesRouter,
  monitoringRouter,
  typologiesRouter,
  discountRouter,
  commitmentsRouter,
  centerDiscountRouter,
  emailTemplateRouter,
  notificationsRouter,
  cryptRouter
} from './routes'
import base64ConverterHelper from 'app/helpers/base64_converter.helper'
type Method = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
type Body = Record<string, unknown>
export type Ctx = { token?: string; permissions?: string[] }
type FetchOptions = {
  url: string
  method: Method
  headers?: HeadersInit
  token?: string
  body?: Body
}

type UploadOptions = {
  url: string
  method: Method
  headers?: HeadersInit
  token?: string
  body?: FormData
}

export const appRouter = {
  accounting: accountingRouter,
  activities: activitiesRouter,
  auth: authRouter,
  centers: centersRouter,
  checkouts: checkoutsRouter,
  clientCenter: clientCenterRouter,
  clientRequests: clientRequestsRouter,
  companies: companiesRouter,
  enterprises: enterprisesRouter,
  monitoring: monitoringRouter,
  consumptionContract: consumptionContractsRouter,
  consumptions: consumptionsRouter,
  contracts: contractsRouter,
  contacts: contactsRouter,
  credits: creditsRouter,
  customerReservations: customerReservationsRouter,
  documents: documentsRouter,
  duplicateEnterprises: duplicateEnterprisesRouter,
  dashboard: dashboardRouter,
  endorsements: endorsementsRouter,
  events: eventsRouter,
  happenings: happeningsRouter,
  filters: filtersRouter,
  formItems: formItemsRouter,
  indexing: indexingRouter,
  individuals: individualsRouter,
  invoices: invoicesRouter,
  litigations: litigationsRouter,
  mandates: mandatesRouter,
  options: optionsRouter,
  occupancy: occupancyRouter,
  opportunities: opportunitiesRouter,
  payments: paymentsRouter,
  prescribers: prescribersRouter,
  pricer: pricerRouter,
  quotations: quotationsRouter,
  rights: rightsRouter,
  services: servicesRouter,
  plannings: planningsRouter,
  floorPlans: floorPlansRouter,
  sources: sourcesRouter,
  staffs: staffsRouter,
  stats: statsRouter,
  wifi: wifiRouter,
  discountGrid: discountGridRouter,
  discountGridCenter: discountGridCenterRouter,
  tertiaries: tertiariesRouter,
  termsOfSales: termsOfSalesRouter,
  typologies: typologiesRouter,
  commitments: commitmentsRouter,
  centerDiscount: centerDiscountRouter,
  emailTemplate: emailTemplateRouter,
  crypt: cryptRouter,
  notifications: notificationsRouter
}

export const appRouterV2 = (ctx: Ctx) => ({
  Discount: discountRouter(ctx)
})

const fetchWithToken = async function ({
  url,
  method,
  headers: additionalHeaders,
  body,
  token
}: FetchOptions): Promise<Response> {
  const headers: HeadersInit = {
    'Content-Type': 'application/json'
  }

  if (additionalHeaders) {
    Object.assign(headers, additionalHeaders)
  }

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  const res = await fetch(url, {
    method,
    body: body ? JSON.stringify(body) : undefined,
    headers
  })

  if (res.status === 401) {
    sessionStorage.removeItem('user')
    localStorage.removeItem('user')
    window.location.reload()
  }

  if (!res.ok) {
    // TODO: Have a better error handling
    const error = await res.json()
    throw new Error(error.message ?? error.error ?? 'error')
  }

  return res
}

export async function fetcher<T>(fetchOptions: FetchOptions): Promise<T> {
  const res = await fetchWithToken(fetchOptions)
  if (res.status !== 200 && res.status !== 201) {
    return null as T
  }

  if (res.headers.get('content-type') !== 'application/json') {
    return null as T
  }

  return (await res.json()) as T
}

export async function uploader<T>({
  url,
  method,
  headers: additionalHeaders,
  body,
  token
}: UploadOptions): Promise<T> {
  const headers: HeadersInit = {}

  if (additionalHeaders) {
    Object.assign(headers, additionalHeaders)
  }

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  const res = await fetch(url, {
    method,
    body: body ? body : undefined,
    headers
  })

  if (res.status === 401) {
    sessionStorage.removeItem('user')
    localStorage.removeItem('user')
    window.location.reload()
  }

  if (res.status === 415) {
    throw new Error('unsupported_media_type')
  }

  if (!res.ok) {
    // TODO: Have a better error handling
    const error = await res.json()
    throw new Error(error.message ?? error.error ?? 'error')
  }

  const contentType = res.headers.get('content-type')
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return (await res.json()) as T
  } else {
    return null as T
  }
}

export async function downloader(
  fetchOptions: FetchOptions,
  filename: string,
  key?: string,
  type?: string
) {
  const res = await fetchWithToken(fetchOptions)
  let data
  if (!key || !type) data = await res.blob()
  else {
    const json = await res.json()
    data = base64ConverterHelper().base64ToBlob(json[key], type) ?? null
  }

  if (!data) return

  const fileUrl = window.URL.createObjectURL(data)
  const a = document.createElement('a')
  a.href = fileUrl
  a.download = filename
  a.click()
  window.URL.revokeObjectURL(fileUrl)
}

export function formatParams(params: string): string {
  return params ? `?${params}` : ''
}
