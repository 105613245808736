import styled from '@emotion/styled'
import { Edit } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { Divider, IconButton as MuiIconButton, Paper as MuiPaper, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React, { useMemo } from 'react'
import { Link } from 'app/components/link.component'
import { TitleComponent } from 'app/components/titles/title.component'
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined'
import { darken } from 'polished'
import { formatDateWithFormat } from 'app/utils/format'
import { CenterDetails } from 'api/models'
import { InfoItemsComponent } from 'app/components/card/info-items.component'

const IconButtonColorActive = styled(MuiIconButton)`
  color: ${(props) => props.theme.sidebar.linkOpen.color};
  background-color: ${(props) => darken(0.03, props.theme.sidebar.linkActive.background)};
`

const ItemName = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 600;
`

interface ICenterDetailsProps {
  center: CenterDetails
  isLoading?: boolean
}

export const CenterDetailsCard = ({ center, isLoading }: ICenterDetailsProps) => {
  const { t } = useTranslation()

  const { INFO_CARD_ITEMS, LOCAL_CARD_ITEMS } = useMemo(
    () => ({
      INFO_CARD_ITEMS: [
        {
          label: t('center_trade_name'),
          value: center?.companyName
        },
        {
          label: t('center_opening'),
          value: center?.openingDate ? formatDateWithFormat(center?.openingDate, 'L') : ''
        },
        {
          label: t('center_program_name'),
          value: center?.realEstateProgram
        },
        {
          label: t('center_lease_signing_date'),
          value: center?.leaseSigningDate ? formatDateWithFormat(center?.leaseSigningDate, 'L') : ''
        },
        {
          label: t('center_date_MADA'),
          value: center?.madaDate ? formatDateWithFormat(center?.madaDate, 'L') : ''
        }
      ],
      LOCAL_CARD_ITEMS: [
        {
          label: t('address'),
          value: center?.address
        },
        {
          label: t('zipcode'),
          value: center?.zipcode
        },
        {
          label: t('city'),
          value: center?.city
        },
        {
          label: t('region'),
          value: center?.region
        },
        {
          label: t('country'),
          value: center?.country
        },
        {
          custom: (
            <Stack key={'gps_coordinate'} direction={'row'} alignItems="center" spacing={4}>
              <Box>
                <Typography variant="caption" sx={{ opacity: 0.85 }}>
                  {t('gps_coordinates')}
                </Typography>
                <ItemName variant="body2">
                  {center.latitude} - {center.longitude}
                </ItemName>
              </Box>
              <IconButtonColorActive size="small" color={'primary'}>
                <PinDropOutlinedIcon />
              </IconButtonColorActive>
            </Stack>
          )
        }
      ]
    }),
    [center]
  )

  return (
    <MuiPaper sx={{ height: '100%' }}>
      <Box paddingBottom={4} paddingTop={4} paddingLeft={4}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
          <TitleComponent text={t('informations')} variant={'h3'} paddingTop={0} paddingLeft={12} />
          <Link to={`/centers/${center?.id}/informations/edit`}>
            <MuiIconButton size="small" color={'primary'}>
              <Edit fontSize="small" />
            </MuiIconButton>
          </Link>
        </Stack>
      </Box>
      <InfoItemsComponent columns={INFO_CARD_ITEMS} isLoading={isLoading} />
      <Divider />
      <Box paddingBottom={4} paddingTop={4} paddingLeft={4}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
          <TitleComponent text={t('location')} variant={'h3'} paddingTop={0} paddingLeft={12} />
          <Link to={`/centers/${center?.id}/location/edit`}>
            <MuiIconButton size="small" color={'primary'}>
              <Edit fontSize="small" />
            </MuiIconButton>
          </Link>
        </Stack>
      </Box>
      <InfoItemsComponent columns={LOCAL_CARD_ITEMS} isLoading={isLoading} />
    </MuiPaper>
  )
}
