import styled from '@emotion/styled'
import { Typography as MuiTypography } from '@mui/material'

const Typography = styled(MuiTypography)`
  position: relative;
  &:before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    background: ${(props) => props.theme.palette.secondary.dark};
    position: absolute;
    bottom: 6px;
    left: 0;
    border-radius: 50%;
  }
`

interface ITitleProps {
  text?: any
  variant?:
    | 'button'
    | 'caption'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'inherit'
    | 'body2'
    | 'body1'
    | 'subtitle1'
    | 'subtitle2'
    | 'overline'
    | undefined
  paddingTop?: number
  paddingLeft?: number
  marginBottom?: number
}

export const TitleComponent = ({
  text,
  paddingTop,
  paddingLeft,
  variant,
  marginBottom
}: ITitleProps): React.JSX.Element => {
  return (
    <Typography
      sx={{ paddingTop: { paddingTop }, paddingLeft: { paddingLeft } }}
      variant={variant}
      marginBottom={marginBottom}
    >
      {text}
    </Typography>
  )
}
