import { useCallback, useState } from 'react'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectChangeEvent
} from '@mui/material'

import { Filter } from 'api/models'
import { SetURLSearchParams } from 'react-router-dom'

interface ISelectProps {
  filter: Filter
  onChange?: () => void
  searchParams: URLSearchParams
  setSearchParams: SetURLSearchParams
}

export function Select({ filter, onChange, searchParams, setSearchParams }: ISelectProps) {
  const [value, setValue] = useState<string>(
    searchParams.get(String(filter.reference)) || filter.default[0]
  )

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      const targetValue = event.target.value
      setValue(targetValue as string)
      setSearchParams((searchParams) => {
        searchParams.set(filter.reference, targetValue)
        return searchParams
      })

      if (onChange) {
        onChange()
      }
    },
    [onChange, filter, setSearchParams, searchParams]
  )

  return (
    <FormControl sx={{ width: 200, height: '100%' }} size={'small'}>
      <InputLabel>{filter.label}</InputLabel>
      <MuiSelect name={filter.reference} value={value} label={filter.label} onChange={handleChange}>
        {filter.values.map((value) => (
          <MenuItem key={value.id} value={value.id}>
            {value.label}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  )
}
