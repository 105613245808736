import React, { useCallback, useEffect } from 'react'
import { Box, Card, CardContent, Typography, Link } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { usePricer } from 'app/providers/pricer.provider'
import { formatCurrency } from '../../../app/utils/format'

type IPriceScalesDrawerComponentProps = {}

const PriceScalesDrawerComponent: React.FC<IPriceScalesDrawerComponentProps> = ({}) => {
  const { t } = useTranslation()
  const { pricerDiscountGrids } = usePricer()

  const distinctGrids = useCallback(
    (type: number) => {
      if (pricerDiscountGrids && pricerDiscountGrids.discountGrids) {
        return pricerDiscountGrids.discountGrids.find((grid) => grid.type === type)
      }
      return undefined
    },
    [pricerDiscountGrids]
  )

  return (
    <Card sx={{ marginBottom: 6 }}>
      <CardContent>
        <Typography variant={'h3'}>{t('discount_grids_label')}</Typography>
        <Box>
          <Link href={'/'} variant={'subtitle2'}>
            {distinctGrids(1)?.label}
          </Link>
        </Box>
        <Box marginBottom={4}>
          <Link href={'/'} variant={'subtitle2'}>
            {distinctGrids(2)?.label}
          </Link>
        </Box>
        <Typography variant={'body2'}>
          {t('center_price', { price: formatCurrency(pricerDiscountGrids.centerPrice) })}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default PriceScalesDrawerComponent
