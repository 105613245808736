import React, { useCallback, useState } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { TextSnippet } from '@mui/icons-material'
import { List } from 'app/components/lists/list'
import { useFetcher } from 'app/providers/fetcher.provider'
import { ListPagination } from 'app/components/lists/list-pagination'
import { useList } from 'app/providers/list.provider'
import { FiltersContainer } from 'app/components/filters/filters-container'
import { useFeedback } from 'app/providers/feedback.provider'
import { ContactsByContractList } from 'api/models/contacts'
import { RangePicker } from 'app/components/filters/range-picker'
import { formatContractTypeId, formatDate, formatIconCheck } from 'app/utils/format'

export const ContactsView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { isLast, orderBy, handleSort, handleFilter } = useList()
  const { getContactByContract, getContactExport, setSearchParams, searchParams } = useFetcher()
  const [contacts, setContacts] = useState<ContactsByContractList>()
  const { handleMutation } = useFeedback()
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([
      ['contract_type', 'contractTypes'],
      ['contact_is_present', 'isPresent'],
      ['centers', 'centers']
    ])
  )

  const [listIsLoading, setListIsLoading] = useState<boolean>(true)

  const refreshList = useCallback(
    async (reset: boolean = true) => {
      await handleMutation({
        onStart: () => setListIsLoading(true),
        mutation: getContactByContract,
        onSuccess: (data) => {
          setContacts(data)
        },
        onEnd: () => setListIsLoading(false)
      })
    },
    [getContactByContract, setListIsLoading]
  )

  const exportToPDF = useCallback(async () => {
    await handleMutation({
      onStart: () => setListIsLoading(true),
      mutation: getContactExport,
      toastSuccess: t('export_success'),
      onEnd: () => setListIsLoading(false)
    })
  }, [getContactExport])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('contacts_list')}
        </Typography>
      </Box>
      <Grid>
        <FiltersContainer
          commonFilters={commonFilters}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          onChange={() => handleFilter(refreshList, true)}
          initialOrder={[{ property: 'reference', order: 'asc' }]}
        >
          <RangePicker
            slug="activeContractBegin"
            slugs={{ begin: 'activeContractBegin', end: 'activeContractEnd' }}
            label={'begin_at'}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <Button variant="contained" startIcon={<TextSnippet />} onClick={exportToPDF}>
            {t('export')}
          </Button>
        </FiltersContainer>
      </Grid>
      <Grid>
        <List
          items={contacts?.items ?? []}
          columns={[
            {
              label: t('reference'),
              slug: 'reference',
              link: { base: '/individuals', slug: 'individualId' }
            },
            {
              label: t('firstname'),
              slug: 'firstname',
              link: { base: '/individuals', slug: 'individualId' }
            },
            {
              label: t('lastname'),
              slug: 'lastname',
              link: { base: '/individuals', slug: 'individualId' }
            },
            { label: t('email'), slug: 'email' },
            {
              label: t('enterprise_name'),
              slug: 'enterpriseName',
              link: { base: '/enterprises', slug: 'enterpriseId' }
            },
            {
              label: t('contract_reference'),
              slug: 'contractReference',
              link: { base: '/contracts', slug: 'contractId' }
            },
            {
              label: t('contract_type'),
              slug: 'contractType',
              valueFormatter: formatContractTypeId
            },
            { label: t('contract_begin'), slug: 'contractBegin', valueFormatter: formatDate },
            { label: t('contract_end'), slug: 'contractEnd', valueFormatter: formatDate },
            {
              label: t('contract_center'),
              slug: 'contractCenter',
              link: { base: '/centers', slug: 'centerId' }
            },
            { label: t('main_center'), slug: 'mainCenterName' },
            {
              label: t('is_present'),
              slug: 'isPresent',
              valueFormatter: formatIconCheck
            }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(refreshList, property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}></Grid>
        {!isLast && <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />}
      </Grid>
    </Container>
  )
}
