import React, { useCallback } from 'react'
import { FormControlLabel, Switch } from '@mui/material'
import { Notification, NotificationUpdate } from 'api/models/notifications'
import { useFeedback } from 'app/providers/feedback.provider'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useTranslation } from 'react-i18next'

interface NotificationStateSwitchProps {
  item: Notification
  refreshList: () => void
}

export function NotificationStateSwitch({ item, refreshList }: NotificationStateSwitchProps) {
  const { t } = useTranslation()
  const { handleMutation } = useFeedback()
  const { updateNotification } = useFetcher()

  const handleUpdate = useCallback(
    async (id: number, data: NotificationUpdate) => {
      await handleMutation({
        mutation: updateNotification,
        data: { id, data },
        onSuccess: () => refreshList()
      })
    },
    [handleMutation, updateNotification, refreshList]
  )

  return (
    <FormControlLabel
      control={
        <Switch
          disabled={!item.isEditable}
          size="small"
          checked={item.isActive}
          onClick={() => handleUpdate(item.id, { isActive: !item.isActive })}
        />
      }
      label={item.isActive ? t('active') : t('inactive')}
    />
  )
}
