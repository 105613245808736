import React, { useCallback, useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { List } from 'app/components/lists/list'
import { useFetcher } from 'app/providers/fetcher.provider'
import { ListPagination } from 'app/components/lists/list-pagination'
import { useList } from 'app/providers/list.provider'
import { FiltersBox } from 'app/components/filters/filter-box'
import { Box, Container } from '@mui/system'
import { formatDateWithTime } from 'app/utils/format'
import { SearchClientInput } from 'app/components/filters/search-client-input'
import { SearchInput } from 'app/components/filters/search-input'
import { RangePicker } from 'app/components/filters/range-picker'
import { Message } from 'api/models'

export const MessageView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { getMessages, setSearchParams, searchParams } = useFetcher()
  const {
    filtersList,
    isLast,
    total,
    orderBy,
    setOffset,
    setTotal,
    setIsLast,
    initFilters,
    handleSort,
    handleFilter
  } = useList()
  const [messages, setMessages] = useState<Message[]>([])
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([
      ['message_type', 'type'],
      ['message_status', 'status']
    ])
  )
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)

  const refreshList = useCallback(
    async (reset: boolean = true) => {
      setListIsLoading(true)
      getMessages
        .mutateAsync()
        .then((data) => {
          setIsLast(data.last)
          setOffset(data.actual)
          setTotal(data.total)
          setMessages(reset ? data.items : (prev) => [...prev, ...data.items])
        })
        .finally(() => setListIsLoading(false))
    },
    [getMessages, setIsLast, setOffset, setTotal]
  )

  useEffect(() => {
    initFilters(commonFilters).then(() => refreshList(true))
  }, [])

  return (
    <Container>
      <Grid item xs={12}>
        <Box marginBottom="2rem">
          <Typography variant="h2" gutterBottom display="inline">
            {t('messages_list')}
          </Typography>
        </Box>
        <Grid>
          <FiltersBox
            filters={filtersList}
            handleFilters={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          >
            <SearchClientInput
              slug="client"
              onChange={() => handleFilter(refreshList, true)}
              setSearchParams={setSearchParams}
              searchParams={searchParams}
            />
            <SearchInput
              slug={'subject'}
              label={'subject'}
              onChange={() => handleFilter(refreshList, true)}
              setSearchParams={setSearchParams}
              searchParams={searchParams}
            />
            <SearchInput
              slug={'contact'}
              label={'contact'}
              onChange={() => handleFilter(refreshList, true)}
              setSearchParams={setSearchParams}
              searchParams={searchParams}
            />
            <RangePicker
              slug="sending_date"
              label={'sending_date'}
              onChange={() => handleFilter(refreshList, true)}
              setSearchParams={setSearchParams}
              searchParams={searchParams}
            />
          </FiltersBox>
        </Grid>
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom>
            {messages.length} / {total}
          </Typography>
        </Grid>
        <List
          items={messages}
          columns={[
            { label: t('sending_date'), slug: 'sendingDate', valueFormatter: formatDateWithTime },
            {
              label: t('client'),
              slug: 'client',
              link: { base: '/individuals', slug: 'clientId' }
            },
            { label: t('subject'), slug: 'subject' },
            { label: t('type'), slug: 'type' },
            { label: t('contact'), slug: 'contact' },
            { label: t('state'), slug: 'status' },
            { label: t('id'), slug: 'id' }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(refreshList, property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {messages.length} / {total}
          </Typography>
        </Grid>
        {!isLast && <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />}
      </Grid>
    </Container>
  )
}
