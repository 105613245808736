import { type Ctx, fetcher, formatParams, uploader } from 'api'
import { BASE_URL, API } from 'api/constants'
import urlHelper from 'app/helpers/url.helper'
import {
  AccessGroup,
  AddClusterProps,
  CenterBlockedPrices,
  CenterClustersList,
  CentersList,
  CreateCenterPriceInputs,
  IdReturn,
  Visors,
  CenterDetails,
  AccessRoom,
  CenterBp
} from 'api/models'
import { CenterPricesList } from 'api/models/centers'

export const centersRouter = {
  getOne: async ({ token }: Ctx, id: string) =>
    fetcher<CenterDetails>({
      url: `${BASE_URL}${urlHelper(API.CENTER, { id })}`,
      method: 'GET',
      token: token
    }),
  listAccessGroups: async ({ token }: Ctx, id: string) =>
    fetcher<AccessGroup[]>({
      url: `${BASE_URL}${urlHelper(API.GET_CENTER_VISOR_GROUPS, { id })}`,
      method: 'GET',
      token: token
    }),
  listAccessRooms: async (
    { token }: Ctx,
    id: string,
    type: string | null,
    status: string | null
  ) => {
    let params = []
    if (type) {
      params.push(`type=${type}`)
    }
    if (status) {
      params.push(`status=${status}`)
    }
    return fetcher<AccessRoom[]>({
      url: `${BASE_URL}${urlHelper(API.VISOR_ROOMS, { id })}${formatParams(params.join('&'))}`,
      method: 'GET',
      token: token
    })
  },
  listVisors: async ({ token }: Ctx) =>
    fetcher<Visors>({
      url: `${BASE_URL}${API.GET_CENTERS_VISORS}`,
      method: 'GET',
      token: token
    }),
  listPrices: async ({ token }: Ctx, params: string) =>
    fetcher<CenterPricesList>({
      url: `${BASE_URL}${API.CENTER_PRICES}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  createPrice: async ({ token }: Ctx, data: CreateCenterPriceInputs) =>
    fetcher<IdReturn>({
      url: `${BASE_URL}${API.CENTER_PRICES}`,
      method: 'POST',
      token: token,
      body: data
    }),
  listBlockedServicePrices: async ({ token }: Ctx, centerId: number) =>
    fetcher<CenterBlockedPrices>({
      url: `${BASE_URL}${urlHelper(API.CENTER_PRICES_BLOCKED_PRICES, { id: centerId })}`,
      method: 'GET',
      token: token
    }),
  list: async ({ token }: Ctx) =>
    fetcher<CentersList>({
      url: `${BASE_URL}${API.GET_CENTERS}`,
      method: 'GET',
      token: token
    }),
  listClusters: async ({ token }: Ctx, params: string) =>
    fetcher<CenterClustersList>({
      url: `${BASE_URL}${API.CLUSTERS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  update: async ({ token }: Ctx, id: number, data: CenterDetails) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.CENTER, { id })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  createCluster: async ({ token }: Ctx, data: AddClusterProps) =>
    fetcher<IdReturn>({
      url: `${BASE_URL}${API.CLUSTERS}`,
      method: 'POST',
      token: token,
      body: data
    }),
  uploadCSV: async ({ token }: Ctx, file: any) => {
    const formData = new FormData()
    formData.append('body', file)
    return uploader<void>({
      url: `${BASE_URL}${API.GET_BUSINESS_PLAN}`,
      method: 'POST',
      token: token,
      body: formData
    })
  },
  businessPlan: async ({ token }: Ctx) => {
    return fetcher<CenterBp[]>({
      url: `${BASE_URL}${API.GET_BUSINESS_PLAN}`,
      method: 'GET',
      token: token
    })
  }
}
