import { type Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import urlHelper from 'app/helpers/url.helper'
import {
  CompaniesListSchema,
  Company,
  CompanyBankAccounts,
  CompanyCenters,
  CompanyConfiguration
} from 'api/models'
export const companiesRouter = {
  list: async ({ token }: Ctx, params: string) =>
    fetcher<CompaniesListSchema>({
      url: `${BASE_URL}${API.GET_COMPANIES}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  get: async ({ token }: Ctx, id: number) =>
    fetcher<Company>({
      url: `${BASE_URL}${urlHelper(API.GET_COMPANY, { id })}`,
      method: 'GET',
      token: token
    }),
  getCenters: async ({ token }: Ctx, id: number) =>
    fetcher<CompanyCenters>({
      url: `${BASE_URL}${urlHelper(API.GET_COMPANY_CENTERS, { id })}`,
      method: 'GET',
      token: token
    }),
  getConfigurations: async ({ token }: Ctx, id: number) =>
    fetcher<CompanyConfiguration>({
      url: `${BASE_URL}${urlHelper(API.GET_COMPANY_CONFIG, { id })}`,
      method: 'GET',
      token: token
    }),
  getBankAccounts: async ({ token }: Ctx, id: number) =>
    fetcher<CompanyBankAccounts>({
      url: `${BASE_URL}${urlHelper(API.GET_COMPANY_BANK_ACCOUNT, { id })}`,
      method: 'GET',
      token: token
    })
}
