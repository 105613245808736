import React, { useCallback, useEffect } from 'react'
import {
  Box,
  Card as MuiCard,
  useTheme,
  CardContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Badge,
  Switch,
  Skeleton
} from '@mui/material'
import styled from '@emotion/styled'
import Avatar from '@mui/material/Avatar'
import { ViewInAr } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { darken } from 'polished'
import { convertHexToRgbA } from 'app/helpers/color.helper'
import { Solution, usePricer } from 'app/providers/pricer.provider'
import { formatCurrency } from 'app/utils/format'
import { Link } from 'app/components/link.component'

const StyledBox = styled(Box)`
  background: ${(props) =>
    props.theme.palette.mode === 'dark'
      ? darken(0.03, props.theme.palette.background.paper)
      : convertHexToRgbA(props.theme.palette.grey[200], 50)};
  padding: ${(props) => props.theme.spacing(4)};

  .MuiTypography-h3 {
    font-size: 1.125rem;
  }
`
const Card = styled(MuiCard)`
  border-top: 2px solid;
  border-top-color: ${(props) => props.theme.palette.secondary.dark};
`

type ISolutionCardComponentProps = {
  solutionReference: string
}

const SolutionCardComponent: React.FC<ISolutionCardComponentProps> = ({ solutionReference }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const {
    solutions,
    currentDiscountGrid,
    onDegressiveChange,
    onDeadlineChange,
    onCommitmentChange,
    onReductionChange,
    pricerInformations,
    pricerDiscountGrids
  } = usePricer()
  const [currentSolution, setCurrentSolution] = React.useState<Solution>({} as Solution)
  const [isLoading, setIsLoading] = React.useState<boolean>(true)

  useEffect(() => {
    if (solutions && solutions.has(solutionReference)) {
      setCurrentSolution(solutions.get(solutionReference) as Solution)
    }
  }, [solutions])

  const getDiscountRate = useCallback(() => {
    if (pricerDiscountGrids.discount && pricerDiscountGrids.discount.rates) {
      const discount = pricerDiscountGrids.discount.rates.find((discount) => {
        return discount.commitmentId === currentSolution.commitment.commitmentId
      })
      return discount?.rate
    }
  }, [pricerDiscountGrids.discount, currentSolution])

  useEffect(() => {
    if (currentDiscountGrid && currentDiscountGrid.commitmentRates) {
      setIsLoading(false)
    }
  }, [currentDiscountGrid])

  return (
    <Grid item xs={3}>
      <Card sx={{ position: 'relative' }}>
        {isLoading ? (
          <Skeleton variant="rectangular" width="100%" height={260} />
        ) : (
          <CardContent sx={{ marginBottom: 0, paddingBottom: '0!important' }}>
            {currentSolution?.commitment?.reference && (
              <Box component="form" marginTop={2}>
                <FormControl fullWidth sx={{ marginBottom: 4 }}>
                  <Select
                    displayEmpty
                    size={'small'}
                    fullWidth={true}
                    value={currentSolution!.commitment!.reference}
                    onChange={(event) => onCommitmentChange(event.target.value, solutionReference)}
                  >
                    {currentDiscountGrid.commitmentRates?.map((commitmentRate, index) => (
                      <MenuItem key={index} value={commitmentRate.reference}>
                        {commitmentRate.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ marginBottom: 4 }}>
                  <TextField
                    size={'small'}
                    fullWidth={true}
                    type={'month'}
                    value={currentSolution!.deadline}
                    InputProps={{
                      inputProps: {
                        min: currentSolution!.commitment.min,
                        max: currentSolution!.commitment.max
                      }
                    }}
                    onChange={(event) => onDeadlineChange(event.target.value, solutionReference)}
                  ></TextField>
                </FormControl>
                <FormControl fullWidth sx={{ marginBottom: 4 }}>
                  <TextField
                    size={'small'}
                    fullWidth={true}
                    type={'number'}
                    value={currentSolution!.reduction}
                    onChange={(event) =>
                      onReductionChange(parseFloat(event.target.value), solutionReference)
                    }
                    InputProps={{
                      inputProps: {
                        min: 0,
                        max: getDiscountRate(),
                        step: 0.1
                      }
                    }}
                  ></TextField>
                </FormControl>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  marginBottom={4}
                >
                  <Typography variant={'caption'}>{t('pricing_without_degressivity')}</Typography>
                  <Switch
                    aria-label={t('pricing_without_degressivity')}
                    size="small"
                    checked={currentSolution!.noDegressive}
                    onChange={() => onDegressiveChange(solutionReference)}
                  />
                </Stack>
                <Divider sx={{ marginBottom: 4 }} />
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Typography variant={'caption'}>
                    {t('standard_price')} {t('12_months')} :
                  </Typography>
                  <Typography variant={'caption'}>
                    {formatCurrency(String(currentSolution?.standardPrice ?? 0))}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Typography variant={'caption'}>{t('price_with_commitment')} :</Typography>
                  <Typography variant={'caption'}>
                    {formatCurrency(String(currentSolution?.commitmentPrice ?? 0))}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Typography variant={'caption'}>{t('monthly_reduc')} :</Typography>
                  <Typography variant={'caption'}>
                    {formatCurrency(String(currentSolution?.monthlyReduction ?? 0))}
                  </Typography>
                </Stack>
              </Box>
            )}
          </CardContent>
        )}
        <Divider sx={{ marginTop: 4, borderColor: theme.palette.primary.light }} />
        <StyledBox>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Box sx={{ textAlign: 'center' }}>
              <Badge
                badgeContent=" "
                variant="dot"
                sx={{ '.MuiBadge-badge': { backgroundColor: theme.palette.secondary.dark } }}
              >
                <Avatar
                  sx={{
                    bgcolor: theme.palette.primary.light,
                    marginLeft: 'auto',
                    marginRight: 'auto'
                  }}
                  variant="square"
                >
                  {solutionReference}
                </Avatar>
              </Badge>
              <Link
                to={`/floorplans?center=${pricerInformations.centerId}&begin=${pricerInformations.dueDate}`}
                style={{ marginLeft: 16 }}
              >
                <IconButton aria-label={t('3D_visit')} size={'small'}>
                  <ViewInAr fontSize={'small'} color={'primary'} />
                </IconButton>
              </Link>
            </Box>
            <Typography variant={'h3'} textAlign={'right'}>
              {' '}
              {formatCurrency(String(currentSolution?.totalPrice ?? 0))}
            </Typography>
          </Stack>
        </StyledBox>
      </Card>
    </Grid>
  )
}

export default SolutionCardComponent
