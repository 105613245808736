import { Grid } from '@mui/material'
import { RectangularSkeleton } from './rectangular.skeleton'

export const FiltersSkeleton = ({ size }: { size: number }) => {
  return (
    <Grid container gap={4} marginY="1rem">
      {Array.from({ length: size }, (_, i) => i).map((i) => (
        <RectangularSkeleton key={i} />
      ))}
    </Grid>
  )
}
