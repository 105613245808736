import { z } from 'zod'
const companyListSchema = z.object({
  id: z.number(),
  reference: z.string(),
  company_name: z.string(),
  siret: z.string(),
  par: z.string(),
  vat: z.string()
})

const companiesListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(companyListSchema)
})

export type CompanyListSchema = z.infer<typeof companyListSchema>
export type CompaniesListSchema = z.infer<typeof companiesListSchema>

const companySchema = z.object({
  id: z.number(),
  reference: z.string(),
  companyName: z.string(),
  siret: z.string(),
  par: z.string(),
  vat: z.string(),
  address: z.string(),
  zipCode: z.string(),
  city: z.string(),
  country: z.string(),
  capital: z.number(),
  rcs: z.string(),
  rcsCity: z.string()
})

export type Company = z.infer<typeof companySchema>

const companyCenters = z.array(
  z.object({
    id: z.number(),
    reference: z.string(),
    centerName: z.string(),
    city: z.string()
  })
)

export type CompanyCenters = z.infer<typeof companyCenters>

const companyConfiguration = z.object({
  id: z.number(),
  gcAccessToken: z.string(),
  stripeSecretKey: z.string(),
  stripePublicKey: z.string(),
  maxAmountDirectDebit: z.number()
})

export type CompanyConfiguration = z.infer<typeof companyConfiguration>

const companyBankAccounts = z.array(
  z.object({
    id: z.number(),
    paymentMode: z.string(),
    iban: z.string(),
    bic: z.string(),
    journalCode: z.string(),
    accountingNumber: z.string(),
    partnerBankAccountId: z.string()
  })
)

export type CompanyBankAccounts = z.infer<typeof companyBankAccounts>
