import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { Container, Stack } from '@mui/system'
import { useTranslation } from 'react-i18next'

import { Payment } from 'api/models'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useParams } from 'react-router-dom'
import { useFeedback } from 'app/providers/feedback.provider'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { formatCurrency, formatDateWithTime } from 'app/utils/format'
import InfoCard from 'app/components/card/info-card.component'
import { Link } from 'app/components/link.component'
import { KeyboardBackspace } from '@mui/icons-material'

export const PaymentView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { handleMutation } = useFeedback()
  const { getPayment } = useFetcher()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [payment, setPayment] = useState<Payment>({} as Payment)

  const fetchPayment = useCallback(async () => {
    await handleMutation({
      onStart: () => setIsLoading(true),
      mutation: getPayment,
      data: Number(id),
      onSuccess: (data) => {
        setPayment(data)
      },
      onEnd: () => setIsLoading(false)
    })
  }, [setIsLoading, getPayment, id, setPayment, handleMutation])

  useEffect(() => {
    fetchPayment().then()
  }, [])

  const { info } = useMemo(() => {
    const info = [
      {
        label: t('payment_type'),
        value: payment.paymentTypeLabel
      },
      {
        label: t('amount'),
        value: formatCurrency(Number(payment.amount))
      },
      {
        label: t('payment_mode_label'),
        value: payment.paymentModeLabel
      },
      {
        label: t('center'),
        value: payment.center
      },
      {
        label: t('created_at'),
        value: payment.created ? formatDateWithTime(payment.created) : ''
      },
      {
        label: t('paid_at'),
        value: payment.paid ? formatDateWithTime(payment.paid) : ''
      },
      {
        label: t('accounting_document'),
        value: (
          <Link to={`/${payment.accountingDocumentType}s/${payment.accountingDocumentId}`}>
            {payment.accountingDocument}
          </Link>
        )
      },
      {
        label: t('client'),
        value: <Link to={`/${payment.clientType}s/${payment.clientId}`}>{payment.client}</Link>
      },
      {
        label: t('contract'),
        value: <Link to={`/contracts/${payment.contractId}`}>{payment.contract}</Link>
      }
    ]

    return { info }
  }, [payment, t])

  return isLoading ? (
    <CardSkeleton />
  ) : (
    <Container>
      <Box sx={{ mt: 2, mb: 2 }}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Link to={`/payments`} style={{ lineHeight: '1em' }}>
            <KeyboardBackspace fontSize={'small'} />
          </Link>
          <Typography variant="h1">
            {t('payment')} | <Link to={`/payments/${payment.id}`}>{payment.reference}</Link>
          </Typography>
        </Stack>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InfoCard title={t('informations')} columns={info} />
        </Grid>
      </Grid>
    </Container>
  )
}
