import { z } from 'zod'
import { languagesSchema } from 'api/models/auth'
import dayjs from 'dayjs'

const savedLabelBodySchema = z.object({
  label: z.string(),
  language: z.number({ coerce: true })
})

const serviceAccountingBodySchema = z.object({
  accountingNumber: z.string()
})

const mainSchema = z.object({
  id: z.number(),
  label: z.string(),
  center: z.string(),
  type: z.string(),
  typology: z.string(),
  capacity: z.number(),
  surface: z.number(),
  price: z.number(),
  dailyPrice: z.number(),
  halfdayPrice: z.number(),
  hourlyPrice: z.number(),
  blockedPrice: z.boolean(),
  begin: z.string(),
  end: z.string()
})

export type Main = z.infer<typeof mainSchema>

const mainListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(mainSchema)
})

export type MainList = z.infer<typeof mainListSchema>

const mainSumSchema = z.object({
  sumCapacity: z.string(),
  sumSurface: z.string()
})

export type MainSum = z.infer<typeof mainSumSchema>

const savedLabelSchema = z.object({
  id: z.number(),
  label: z.string(),
  language: languagesSchema
})

const serviceAlertsSchema = z.array(
  z.object({
    id: z.number(),
    label: z.string(),
    type: z.string()
  })
)

export type ServiceAlerts = z.infer<typeof serviceAlertsSchema>

const mainServiceSchema = z.object({
  id: z.number(),
  savedLabels: z.array(savedLabelSchema),
  serviceTypeId: z.number(),
  serviceTypeLabel: z.string(),
  centerId: z.number(),
  centerName: z.string(),
  surface: z.number().nullable(),
  maxCapacity: z.number().nullable(),
  typologyLabel: z.string(),
  typologyId: z.number(),
  floor: z.number(),
  begin: z.string(),
  end: z.string().nullable(),
  monthlyPrice: z.number().nullable(),
  dailyPrice: z.number().nullable(),
  halfDayPrice: z.number().nullable(),
  hourlyPrice: z.number().nullable(),
  currencyName: z.string(),
  description: z.string().nullable(),
  isOnline: z.boolean(),
  isMonthlyPriceBlocked: z.boolean(),
  price: z.number().nullable(),
  centerPrice: z.number(),
  typologiesRate: z.record(z.number()),
  building: z.string().optional(),
  alerts: serviceAlertsSchema
})

export type MainService = z.infer<typeof mainServiceSchema>

const optionServiceSchema = z.object({
  savedLabels: z.array(savedLabelSchema),
  serviceTypeLabel: z.string(),
  begin: z.string().nullable(),
  end: z.string().nullable(),
  monthlyPrice: z.number().nullable(),
  currencyName: z.string(),
  isOnline: z.boolean(),
  firstAccountingNumber: z.string(),
  isFreePrice: z.boolean(),
  isFreeFees: z.boolean(),
  commissioningFees: z.number().nullable()
})

export type OptionService = z.infer<typeof optionServiceSchema>

export const optionServiceBodySchema = z
  .object({
    price: z.number({ coerce: true }).nullable(),
    isFreePrice: z.boolean().nullable(),
    isFreeFees: z.boolean().nullable(),
    commissioningFees: z.number({ coerce: true }).nullable(),
    serviceType: z.number().nullable(),
    begin: z.instanceof(dayjs as any).nullable(),
    end: z
      .instanceof(dayjs as any)
      .nullable()
      .optional(),
    isOnline: z.boolean().nullable(),
    serviceAccounting: z.array(serviceAccountingBodySchema),
    savedLabels: z.array(savedLabelBodySchema)
  })
  .transform((data) => ({
    ...data,
    price: data.isFreePrice ? null : data.price,
    commissioningFees: data.isFreeFees ? null : data.commissioningFees,
    savedLabels: data.savedLabels.filter((data) => data.label)
  }))

export type OptionServiceBody = z.infer<typeof optionServiceBodySchema>

const savedLabelsBodySchema = z
  .object({ savedLabels: z.array(savedLabelBodySchema) })
  .transform((data) => ({
    savedLabels: data.savedLabels.filter((data) => data.label)
  }))

export type SavedLabelsBody = z.infer<typeof savedLabelsBodySchema>

export const mainServiceBodySchema = z
  .object({
    savedLabels: z.array(savedLabelBodySchema),
    isOnline: z.boolean().optional(),
    surface: z.number({ coerce: true }).gt(0),
    maxCapacity: z.number({ coerce: true }).optional(),
    typology: z.number({ coerce: true }).nullable().optional(),
    floor: z.number({ coerce: true }).optional(),
    isMassivePrice: z.boolean().optional(),
    begin: z.instanceof(dayjs as any),
    end: z
      .instanceof(dayjs as any)
      .nullable()
      .optional(),
    description: z.string().nullable().optional(),
    price: z.number({ coerce: true }).optional(),
    dailyPrice: z.number({ coerce: true }).optional(),
    halfDayPrice: z.number({ coerce: true }).optional(),
    hourlyPrice: z.number({ coerce: true }).optional()
  })
  .transform((data) => ({
    ...data,
    savedLabels: data.savedLabels.filter((data) => data.label),
    price: data.price && data.price > 0 ? data.price : null,
    dailyPrice: data.dailyPrice && data.dailyPrice > 0 ? data.dailyPrice : null,
    halfDayPrice: data.halfDayPrice && data.halfDayPrice > 0 ? data.halfDayPrice : null,
    hourlyPrice: data.hourlyPrice && data.hourlyPrice > 0 ? data.hourlyPrice : null,
    typology: data.typology && data.typology > 0 ? data.typology : null
  }))

export type MainServiceBody = z.infer<typeof mainServiceBodySchema>
export const mainServiceAddBodySchema = z
  .object({
    center: z.number(),
    serviceType: z.number(),
    savedLabels: z.array(savedLabelBodySchema),
    isOnline: z.boolean().optional(),
    surface: z.number({ coerce: true }).gt(0),
    maxCapacity: z.number({ coerce: true }).optional(),
    typology: z.number({ coerce: true }).nullable().optional(),
    floor: z.number({ coerce: true }).optional(),
    begin: z.instanceof(dayjs as any),
    end: z
      .instanceof(dayjs as any)
      .nullable()
      .optional(),
    description: z.string().nullable().optional(),
    price: z.number({ coerce: true }).optional(),
    dailyPrice: z.number({ coerce: true }).optional(),
    halfDayPrice: z.number({ coerce: true }).optional(),
    hourlyPrice: z.number({ coerce: true }).optional()
  })
  .transform((data) => ({
    ...data,
    savedLabels: data.savedLabels.filter((data) => data.label),
    price: data.price && data.price > 0 ? data.price : null,
    dailyPrice: data.dailyPrice && data.dailyPrice > 0 ? data.dailyPrice : null,
    halfDayPrice: data.halfDayPrice && data.halfDayPrice > 0 ? data.halfDayPrice : null,
    hourlyPrice: data.hourlyPrice && data.hourlyPrice > 0 ? data.hourlyPrice : null,
    typology: data.typology && data.typology > 0 ? data.typology : null
  }))

export type MainServiceAddBody = z.infer<typeof mainServiceAddBodySchema>

export const mainServiceComputePriceBodySchema = z.object({
  center: z.string(),
  serviceType: z.string(),
  typology: z.string().nullable().optional(),
  surface: z.string().nullable().optional()
})

export type MainServiceComputePriceBody = z.infer<typeof mainServiceComputePriceBodySchema>

export const parkingServiceBodySchema = z
  .object({
    price: z.number({ coerce: true }).optional(),
    typology: z.number({ coerce: true }),
    begin: z.instanceof(dayjs as any),
    end: z
      .instanceof(dayjs as any)
      .nullable()
      .optional(),
    maxCapacity: z.number({ coerce: true }).optional(),
    floor: z.number({ coerce: true }).optional(),
    isOnline: z.boolean().optional(),
    building: z.string().optional(),
    savedLabels: z.array(savedLabelBodySchema)
  })
  .transform((data) => ({
    ...data,
    price: data.price && data.price > 0 ? data.price : null,
    savedLabels: data.savedLabels.filter((data) => data.label)
  }))

export type ParkingServiceBody = z.infer<typeof parkingServiceBodySchema>

export const consumptionServiceSchema = z.object({
  id: z.number(),
  savedLabels: z.array(savedLabelSchema),
  serviceTypeLabel: z.string(),
  begin: z.string().nullable(),
  end: z.string().nullable(),
  price: z.number().nullable(),
  currencyName: z.string(),
  isOnline: z.boolean(),
  firstAccountingNumber: z.string(),
  isFreePrice: z.boolean(),
  isFreeFees: z.boolean(),
  commissioningFees: z.number().nullable()
})

export type ConsumptionService = z.infer<typeof consumptionServiceSchema>
