import React, { useCallback, useEffect, useState } from 'react'
import { Container } from '@mui/system'
import { Box, Grid, Typography } from '@mui/material'
import { FiltersBox } from 'app/components/filters/filter-box'
import { List } from 'app/components/lists/list'
import { ListPagination } from 'app/components/lists/list-pagination'
import { useList } from 'app/providers/list.provider'
import { useFetcher } from 'app/providers/fetcher.provider'
import { DiscountGrids } from 'api/models'
import { useTranslation } from 'react-i18next'
import { formatDiscountGridType } from 'app/utils/format'
import { useFeedback } from 'app/providers/feedback.provider'

export const DiscountGridsView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const {
    filtersList,
    isLast,
    total,
    orderBy,
    setOffset,
    setTotal,
    setIsLast,
    initFilters,
    handleSort,
    handleFilter
  } = useList()
  const { getDiscountGrids, searchParams, setSearchParams } = useFetcher()
  const [discountGrids, setDiscountGrids] = useState<DiscountGrids[]>([])
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const { handleMutation } = useFeedback()
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([
      ['discount_grid_type', 'type'],
      ['active_status', 'active']
    ])
  )

  const refreshList = useCallback(
    async (reset: boolean) => {
      await handleMutation({
        onStart: () => setListIsLoading(true),
        mutation: getDiscountGrids,
        onSuccess: (data) => {
          setOffset(data.actual)
          setIsLast(data.last)
          setTotal(data.total)
          setDiscountGrids(reset ? data.items : (prev) => [...prev, ...data.items])
        },
        onEnd: () => setListIsLoading(false)
      })
    },
    [discountGrids, setIsLast, setOffset, setTotal]
  )

  useEffect(() => {
    initFilters(commonFilters).then(() => refreshList(true))
  }, [])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('discount_grids_list')}
        </Typography>
      </Box>
      <Grid>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => handleFilter(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        ></FiltersBox>
      </Grid>
      <Grid>
        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {discountGrids.length} / {total}
          </Typography>
        </Grid>
        <List
          items={discountGrids}
          columns={[
            { label: t('label'), slug: 'label', link: { base: '/discountgrids', slug: 'id' } },
            { label: t('type'), slug: 'type', valueFormatter: formatDiscountGridType }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(() => refreshList(true), property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {discountGrids.length} / {total}
          </Typography>
        </Grid>
        {!isLast && <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />}
      </Grid>
    </Container>
  )
}
