import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from '@mui/system'
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material'

import { List } from 'app/components/lists/list'
import { ListPagination } from 'app/components/lists/list-pagination'
import { formatCurrency, formatDate, formatSurface } from 'app/utils/format'
import type { Main, MainSum } from 'api/models'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useList } from 'app/providers/list.provider'
import { SummaryComponent } from 'app/components/lists/summary.component'
import { AddCircle, PeopleAlt, SquareFoot } from '@mui/icons-material'
import LockIcon from '@mui/icons-material/Lock'
import { useApp } from 'app/providers/app.provider'
import { useNavigate } from 'react-router-dom'
import { FiltersContainer } from 'app/components/filters/filters-container'

const formatServicePrice = function (data: Main) {
  return (
    <Stack gap={1} direction={'row'}>
      {data.blockedPrice && <LockIcon fontSize={'small'} />}
      {formatCurrency(data.price)}
    </Stack>
  )
}

export const MainServicesView = (): React.JSX.Element => {
  const { getRight } = useApp()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { isLast, total, orderBy, setOffset, setTotal, setIsLast, handleSort, handleFilter } =
    useList()
  const { getMainServices, getMainServiceSum, searchParams, setSearchParams } = useFetcher()
  const [mainServices, setMainServices] = useState<Main[]>([])
  const [summary, setSummary] = useState<MainSum>({} as MainSum)
  const [summaryItems, setSummaryItems] = useState<Map<string, string>>(new Map<string, string>())
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([
      ['main_services_types', 'type'],
      ['main_services_typologies', 'typology'],
      ['main_services_expired', 'expired'],
      ['staff_centers', 'center']
    ])
  )
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const [summaryIsLoading, setSummaryIsLoading] = useState<boolean>(true)

  const refreshList = useCallback(
    async (reset: boolean) => {
      setListIsLoading(true)
      getMainServices
        .mutateAsync()
        .then((data) => {
          setOffset(data.actual)
          setIsLast(data.last)
          setTotal(data.total)
          setMainServices(reset ? data.items : (prev) => [...prev, ...data.items])
        })
        .finally(() => setListIsLoading(false))

      setSummaryIsLoading(true)
      await getMainServiceSum
        .mutateAsync()
        .then((sum) => setSummary(sum))
        .finally(() => setSummaryIsLoading(false))
    },
    [getMainServiceSum, getMainServices, setIsLast, setOffset, setTotal]
  )

  useEffect(() => {
    const newSummaryItems = new Map<string, string>()
    newSummaryItems.set('sumCapacity', summary.sumCapacity)
    newSummaryItems.set('total_surface', formatSurface(summary.sumSurface))
    setSummaryItems(newSummaryItems)
  }, [summary])

  return (
    <Container>
      <Box marginBottom="1rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('main_services')}
          {getRight('catalog_service', 'isAdd') && (
            <IconButton
              title={t('add_service')}
              aria-label={t('add_service')}
              color="primary"
              onClick={() => navigate('/services/add')}
              style={{ marginLeft: 7 }}
            >
              <AddCircle fontSize="small" />
            </IconButton>
          )}
        </Typography>
      </Box>
      <Grid>
        <FiltersContainer
          commonFilters={commonFilters}
          onChange={() => handleFilter(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        ></FiltersContainer>
      </Grid>
      <Grid>
        <SummaryComponent items={summaryItems} isLoading={summaryIsLoading} />

        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {mainServices.length} / {total}
          </Typography>
        </Grid>
        <List
          items={mainServices}
          columns={[
            {
              label: t('label'),
              slug: 'label',
              unsorted: true,
              link: { base: '/services/main', slug: 'id' }
            },
            { label: t('center'), slug: 'center', unsorted: true },
            { label: t('type'), slug: 'type', unsorted: true },
            { label: t('typology'), slug: 'typology', unsorted: true },
            { label: PeopleAlt, slug: 'capacity', unsorted: true },
            { label: SquareFoot, slug: 'surface', valueFormatter: formatSurface, unsorted: true },
            { label: t('price'), slug: 'price', condition: formatServicePrice, unsorted: true },
            {
              label: t('daily_price'),
              slug: 'dailyPrice',
              valueFormatter: formatCurrency,
              unsorted: true
            },
            {
              label: t('half_day_price'),
              slug: 'halfdayPrice',
              valueFormatter: formatCurrency,
              unsorted: true
            },
            {
              label: t('hourly_price'),
              slug: 'hourlyPrice',
              valueFormatter: formatCurrency,
              unsorted: true
            },
            { label: t('begin'), slug: 'begin', valueFormatter: formatDate, unsorted: true },
            { label: t('end'), slug: 'end', valueFormatter: formatDate, unsorted: true }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(() => refreshList(true), property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {mainServices.length} / {total}
          </Typography>
        </Grid>
        {!isLast && <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />}
      </Grid>
    </Container>
  )
}
