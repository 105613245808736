import { type Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import {
  AcquisitionsStats,
  ClientRequest,
  Inventories,
  Occupations,
  PerformanceStats,
  StatsCA,
  Surface
} from 'api/models/stats'

export const statsRouter = {
  occupations: async ({ token }: Ctx, params: string) =>
    fetcher<Occupations>({
      url: `${BASE_URL}${API.GET_STATS_OCCUPATION}${formatParams(
        params
      )}&serviceTypes=${JSON.stringify(['FULL_DESKTOP'])}&contractTypes=${JSON.stringify([1, 2])}`,
      method: 'GET',
      token: token
    }),
  inventories: async ({ token }: Ctx, params: string) =>
    fetcher<Inventories>({
      url: `${BASE_URL}${API.GET_STATS_INVENTORY}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  surface: async ({ token }: Ctx, params: string) =>
    fetcher<Surface>({
      url: `${BASE_URL}${API.GET_STATS_SURFACE}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  clientRequests: async ({ token }: Ctx, params: string) =>
    fetcher<ClientRequest>({
      url: `${BASE_URL}${API.GET_STATS_CLIENT_REQUEST}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  performance: async ({ token }: Ctx, params: string) =>
    fetcher<PerformanceStats>({
      url: `${BASE_URL}${API.GET_STATS_PERFORMANCE}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  acquisitions: async ({ token }: Ctx, params: string) =>
    fetcher<AcquisitionsStats>({
      url: `${BASE_URL}${API.GET_STATS_ACQUISITIONS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  CA: async ({ token }: Ctx, params: string) =>
    fetcher<StatsCA>({
      url: `${BASE_URL}${API.GET_STATS_CA}${formatParams(params)}`,
      method: 'GET',
      token: token
    })
}
