import { useTranslation } from 'react-i18next'
import { Box, Card, Grid } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { List } from 'app/components/lists/list'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useList } from 'app/providers/list.provider'
import { TitleComponent } from 'app/components/titles/title.component'
import { Company, CompanyBankAccounts } from 'api/models/companies'

interface ICompanyCenters {
  company: Company
}

export function CompanyBankAccountsComponent({ company }: ICompanyCenters) {
  const { t } = useTranslation()
  const [items, setItems] = useState<CompanyBankAccounts>([])
  const [isLoading, setIsLoading] = useState(false)
  const { orderBy, handleSort, handleFilter } = useList()
  const { getCompanyBankAccounts } = useFetcher()

  const refreshList = useCallback(async () => {
    setIsLoading(true)
    try {
      const data = await getCompanyBankAccounts.mutateAsync(company.id)
      setItems(data)
    } finally {
      setIsLoading(false)
    }
  }, [company.id])

  useEffect(() => {
    refreshList().then()
  }, [refreshList])

  return (
    <Card>
      <Box paddingBottom={4} paddingTop={4} paddingLeft={4}>
        <TitleComponent text={t('bank_accounts')} variant={'h3'} paddingLeft={12} />
      </Box>
      <Grid container columns={4}>
        <Grid item xs={4}>
          <List
            items={items}
            selectable={false}
            columns={[
              {
                label: t('id'),
                slug: 'id'
              },
              {
                label: t('journal_code'),
                slug: 'journalCode'
              },
              {
                label: t('accounting_number'),
                slug: 'accountingNumber'
              },
              {
                label: t('payment_mode_label'),
                slug: 'paymentMode'
              },
              {
                label: t('partner_reference'),
                slug: 'partnerBankAccountId'
              },
              {
                label: t('iban'),
                slug: 'iban'
              },
              {
                label: t('bic'),
                slug: 'bic'
              }
            ]}
            handleReset={() => handleFilter(refreshList, true)}
            sort={orderBy}
            handleSort={(property) => handleSort(refreshList, property)}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </Card>
  )
}
