import React, { useCallback, useEffect, useState } from 'react'
import { Container } from '@mui/system'
import { Box, Grid, ListItem, Typography, List as MuiList, IconButton } from '@mui/material'
import { List } from 'app/components/lists/list'
import { ListPagination } from 'app/components/lists/list-pagination'
import { useList } from 'app/providers/list.provider'
import { useFetcher } from 'app/providers/fetcher.provider'
import { CenterClustersItem, ClusterCenter } from 'api/models'
import { useTranslation } from 'react-i18next'
import { useFeedback } from 'app/providers/feedback.provider'
import { AddCircle, CancelRounded, CircleRounded } from '@mui/icons-material'
import { useApp } from 'app/providers/app.provider'
import { AddClusterForm } from 'modules/centers/components/add-cluster-form.component'

const formatClusterCenters = (centers: ClusterCenter[]) => (
  <MuiList>
    {centers.length > 0 &&
      centers.map((center) => (
        <ListItem disablePadding key={center.id}>
          <CircleRounded sx={{ fontSize: '0.25rem', marginRight: 2 }} />
          {center.name}
        </ListItem>
      ))}
  </MuiList>
)

export const ClustersView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { isLast, total, orderBy, setOffset, setTotal, setIsLast, handleSort, handleFilter } =
    useList()
  const { getRight } = useApp()
  const { handleMutation } = useFeedback()
  const { getCentersClusters } = useFetcher()
  const [clusters, setClusters] = useState<CenterClustersItem[]>([])
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const [isAddOpen, setIsAddOpen] = useState<boolean>()

  const refreshList = useCallback(async (reset: boolean) => {
    await handleMutation({
      onStart: () => setListIsLoading(true),
      mutation: getCentersClusters,
      onSuccess: (data) => {
        setOffset(data.actual)
        setIsLast(data.last)
        setTotal(data.total)
        setClusters(reset ? data.items : (prev) => [...prev, ...data.items])
      },
      onEnd: () => setListIsLoading(false)
    })
  }, [])

  const openAddForm = useCallback(() => {
    setIsAddOpen((prev) => !prev)
  }, [])

  useEffect(() => {
    refreshList(true).then()
  }, [])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('centers_clusters_list')}
          {getRight('center_cluster', 'isAdd') && (
            <IconButton
              title={t('add_cluster')}
              aria-label={t('add_cluster')}
              color="primary"
              onClick={openAddForm}
              style={{ marginLeft: 7 }}
            >
              {isAddOpen ? <CancelRounded fontSize="small" /> : <AddCircle fontSize="small" />}
            </IconButton>
          )}
        </Typography>
      </Box>
      <Grid>
        {isAddOpen && <AddClusterForm />}
        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {clusters.length} / {total}
          </Typography>
        </Grid>
        <List
          items={clusters}
          columns={[
            { label: t('name'), slug: 'name' },
            { label: t('centers'), slug: 'centers', valueFormatter: formatClusterCenters }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(() => refreshList(true), property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {clusters.length} / {total}
          </Typography>
        </Grid>
        {!isLast && <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />}
      </Grid>
    </Container>
  )
}
