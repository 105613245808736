import React, { useCallback, useEffect, useState } from 'react'
import { Container } from '@mui/system'
import { Box, Grid, Typography } from '@mui/material'
import { FiltersBox } from 'app/components/filters/filter-box'
import { ListPagination } from 'app/components/lists/list-pagination'
import { useList } from 'app/providers/list.provider'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useTranslation } from 'react-i18next'
import { SearchCenterInput } from 'app/components/filters/search-center-input'
import { SearchClientInput } from 'app/components/filters/search-client-input'
import { RangePicker } from 'app/components/filters/range-picker'
import { Checkout, CheckoutSum } from 'api/models'
import { List } from 'app/components/lists/list'
import { SummaryComponent } from 'app/components/lists/summary.component'
import {
  formatCommitment,
  formatContractState,
  formatContractType,
  formatCurrency,
  formatDate,
  formatStaffBadge,
  formatSurface
} from 'app/utils/format'
import { SearchStaffInput } from 'app/components/filters/search-staff-input'
import {
  HourglassTop,
  CalendarToday,
  Handshake,
  SquareFoot,
  EuroSymbol,
  AccountCircle,
  PlayArrow
} from '@mui/icons-material'
import { useApp } from 'app/providers/app.provider'

export const CheckoutsView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { user } = useApp()
  const {
    filtersList,
    isLast,
    total,
    orderBy,
    setOffset,
    setTotal,
    setIsLast,
    initFilters,
    handleSort,
    handleFilter
  } = useList()
  const { getCheckouts, getCheckoutSum, searchParams, setSearchParams } = useFetcher()
  const [checkouts, setCheckouts] = useState<Checkout[]>([])
  const [summary, setSummary] = useState<CheckoutSum>({} as CheckoutSum)
  const [summaryItems, setSummaryItems] = useState<Map<string, string>>(new Map<string, string>())
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const [summaryIsLoading, setSummaryIsLoading] = useState<boolean>(true)
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([
      ['contract_type', 'contract_types'],
      ['endorsement_reasons', 'endorsement_reasons'],
      ['contract_status', 'endorsement_states']
    ])
  )

  const refreshList = useCallback(
    async (reset: boolean) => {
      setListIsLoading(true)
      getCheckouts
        .mutateAsync()
        .then((data) => {
          setOffset(data.actual)
          setIsLast(data.last)
          setTotal(data.total)
          setCheckouts(reset ? data.items : (prev) => [...prev, ...data.items])
        })
        .finally(() => setListIsLoading(false))

      setSummaryIsLoading(true)
      getCheckoutSum
        .mutateAsync()
        .then((sum) => setSummary(sum))
        .finally(() => setSummaryIsLoading(false))
    },
    [getCheckoutSum, getCheckouts, setIsLast, setOffset, setTotal]
  )

  useEffect(() => {
    const newSummaryItems = new Map<string, string>()
    newSummaryItems.set('contractCount', formatCurrency(summary.contractCount ?? 0))
    newSummaryItems.set('endorsementCount', formatCurrency(summary.endorsementCount ?? 0))
    newSummaryItems.set('contractPriceSum', formatCurrency(summary.contractPriceSum ?? 0))
    newSummaryItems.set('contractSurfaceSum', formatCurrency(summary.contractSurfaceSum ?? 0))
    newSummaryItems.set(
      'endorsementCommitmentDifference',
      formatCurrency(summary.endorsementCommitmentDifference ?? 0)
    )
    newSummaryItems.set(
      'endorsementAmountDifference',
      formatCurrency(summary.endorsementAmountDifference ?? 0)
    )
    newSummaryItems.set(
      'endorsementSurfaceDifferenceSum',
      formatCurrency(summary.endorsementSurfaceDifferenceSum ?? 0)
    )
    setSummaryItems(newSummaryItems)
  }, [summary])

  useEffect(() => {
    initFilters(commonFilters).then(() => refreshList(true))
  }, [])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('checkouts_list')}
        </Typography>
      </Box>
      <Grid>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => handleFilter(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <SearchCenterInput
            slug="center"
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            byPassAllCenters={true}
            defaultIsCluster={true}
            initialValue={user?.mainCenter}
          />
          <SearchClientInput
            slug="client"
            defaultIsIndividual={false}
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <RangePicker
            slug="deadline"
            label={'checkout_period'}
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <RangePicker
            slug="prior_notice"
            label={'prior_notice'}
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <SearchStaffInput
            slug="staff"
            onChange={() => handleFilter(refreshList, true)}
            searchParams={searchParams}
          />
        </FiltersBox>
      </Grid>
      <Grid>
        <SummaryComponent items={summaryItems} isLoading={summaryIsLoading} />

        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {checkouts.length} / {total}
          </Typography>
        </Grid>
        <List
          items={checkouts}
          columns={[
            {
              label: t('reference'),
              slug: 'contractReference',
              link: { base: '/contracts', slug: 'contractId' }
            },
            { label: HourglassTop, slug: 'contractDeadline', valueFormatter: formatDate },
            { label: CalendarToday, slug: 'contractPriorNotice', valueFormatter: formatDate },
            {
              label: t('client'),
              slug: 'clientName',
              link: { base: '/enterprises', slug: 'clientId' }
            },
            { label: t('center'), slug: 'centerName' },
            {
              label: t('type'),
              slug: 'contractType',
              text: 'contractTypeLabel',
              valueFormatter: formatContractType
            },
            { label: Handshake, slug: 'contractCommitment', valueFormatter: formatCommitment },
            { label: SquareFoot, slug: 'contractSurface', valueFormatter: formatSurface },
            { label: EuroSymbol, slug: 'contractPrice', valueFormatter: formatCurrency },
            {
              label: t('square_meter_price'),
              slug: 'contractSquareMeterPrice',
              valueFormatter: formatCurrency
            },
            {
              label: t('endorsement_state'),
              slug: 'endorsementState',
              text: 'endorsementStateLabel',
              valueFormatter: formatContractState
            },
            {
              label: t('endorsement_reference'),
              slug: 'endorsementReference',
              link: { base: '/contracts', slug: 'endorsementId' }
            },
            { label: t('endorsement_reason'), slug: 'endorsementReasonLabel' },
            {
              label: AccountCircle,
              slug: 'endorsementStaffLight',
              text: 'endorsementStaff',
              valueFormatter: formatStaffBadge
            },
            {
              label: Handshake,
              slug: 'endorsementCommitment',
              text: 'commitmentDifference',
              valueFormatter: formatCommitment
            },
            {
              label: SquareFoot,
              slug: 'endorsementSurface',
              text: 'surfaceDifference',
              valueFormatter: formatSurface
            },
            {
              label: EuroSymbol,
              slug: 'endorsementPrice',
              text: 'amountDifference',
              valueFormatter: formatCurrency
            },
            {
              label: t('endorsement_square_meter_price'),
              slug: 'endorsementSquareMeterPrice',
              valueFormatter: formatCurrency
            },
            { label: PlayArrow, slug: 'endorsementBegin', valueFormatter: formatDate }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(() => refreshList(true), property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {checkouts.length} / {total}
          </Typography>
        </Grid>
        {!isLast && <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />}
      </Grid>
    </Container>
  )
}
