import React, { useCallback, useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { List } from 'app/components/lists/list'
import { useFetcher } from 'app/providers/fetcher.provider'
import { ListPagination } from 'app/components/lists/list-pagination'
import { useList } from 'app/providers/list.provider'
import { Box, Container } from '@mui/system'
import { CompanyListSchema } from 'api/models/companies'

export const CompaniesView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { getCompanies } = useFetcher()
  const {
    initSort,
    isLast,
    total,
    orderBy,
    setOffset,
    setTotal,
    setIsLast,
    handleSort,
    handleFilter
  } = useList()
  const [companies, setCompanies] = useState<CompanyListSchema[]>([])
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)

  const refreshList = useCallback(
    async (reset: boolean = true) => {
      setListIsLoading(true)
      getCompanies
        .mutateAsync()
        .then((data) => {
          setIsLast(data.last)
          setOffset(data.actual)
          setTotal(data.total)
          setCompanies(reset ? data.items : (prev) => [...prev, ...data.items])
        })
        .finally(() => setListIsLoading(false))
    },
    [getCompanies, setIsLast, setOffset, setTotal]
  )

  useEffect(() => {
    initSort([]).then(() => refreshList(true))
  }, [])

  return (
    <Container>
      <Grid item xs={12}>
        <Box marginBottom="2rem">
          <Typography variant="h2" gutterBottom display="inline">
            {t('companies_list')}
          </Typography>
        </Box>
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom>
            {companies.length} / {total}
          </Typography>
        </Grid>
        <List
          items={companies}
          columns={[
            {
              label: t('id'),
              slug: 'id',
              link: { base: '/companies', slug: 'id' }
            },
            {
              label: t('reference'),
              slug: 'reference',
              link: { base: '/companies', slug: 'id' }
            },
            {
              label: t('company_name'),
              slug: 'company_name',
              link: { base: '/companies', slug: 'id' }
            },
            {
              label: t('siret'),
              slug: 'siret'
            },
            {
              label: t('par'),
              slug: 'par'
            },
            {
              label: t('vat'),
              slug: 'vat'
            }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(refreshList, property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {companies.length} / {total}
          </Typography>
        </Grid>
        {!isLast && <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />}
      </Grid>
    </Container>
  )
}
