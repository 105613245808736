import React, { useCallback, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'

import { List } from 'app/components/lists/list'
import { useFetcher } from 'app/providers/fetcher.provider'
import { ListPagination } from 'app/components/lists/list-pagination'
import { SearchCenterInput } from 'app/components/filters/search-center-input'
import { useList } from 'app/providers/list.provider'
import {
  formatCommitment,
  formatContractState,
  formatContractType,
  formatCurrency,
  formatDate,
  formatSurface
} from 'app/utils/format'
import { SearchClientInput } from 'app/components/filters/search-client-input'
import { RangePicker } from 'app/components/filters/range-picker'
import { Endorsement } from 'api/models/endorsements'
import { PlayArrow } from '@mui/icons-material'
import { useApp } from 'app/providers/app.provider'
import { FiltersContainer } from 'app/components/filters/filters-container'

export const EndorsementsView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { user } = useApp()
  const { isLast, total, orderBy, setOffset, setTotal, setIsLast, handleSort, handleFilter } =
    useList()
  const { getEndorsements, searchParams, setSearchParams } = useFetcher()
  const [endorsements, setEndorsements] = useState<Endorsement[]>([])
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([
      ['contract_type', 'types'],
      ['contract_status', 'states'],
      ['agreement_types', 'agreement_types']
    ])
  )

  const [listIsLoading, setListIsLoading] = useState<boolean>(true)

  const refreshList = useCallback(
    async (reset: boolean = true) => {
      setListIsLoading(true)
      getEndorsements
        .mutateAsync()
        .then((data) => {
          setOffset(data.actual)
          setIsLast(data.last)
          setTotal(data.total)
          setEndorsements(reset ? data.items : (prev) => [...prev, ...data.items])
        })
        .finally(() => setListIsLoading(false))
    },
    [getEndorsements, setIsLast, setOffset, setTotal]
  )

  return (
    <Container>
      <Box marginBottom="1rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('endorsements_list')}
        </Typography>
      </Box>
      <Grid>
        <FiltersContainer
          commonFilters={commonFilters}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          onChange={() => handleFilter(refreshList, true)}
        >
          <SearchCenterInput
            slug="center"
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            defaultIsCluster={true}
            byPassAllCenters={true}
            initialValue={user?.mainCenter}
          />
          <SearchClientInput
            slug="client"
            defaultIsIndividual={false}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <RangePicker
            slug="begin_at"
            label={'begin_at'}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <RangePicker
            slug="created_at"
            label={'created_at'}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
        </FiltersContainer>
      </Grid>
      <Grid>
        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {endorsements.length} / {total}
          </Typography>
        </Grid>
        <List
          items={endorsements}
          columns={[
            {
              label: t('state'),
              slug: 'state',
              text: 'stateLabel',
              valueFormatter: formatContractState
            },
            {
              label: t('reference'),
              slug: 'endorsementReference',
              link: { base: '/contracts', slug: 'endorsement_id' }
            },
            {
              label: t('origine_contract'),
              slug: 'parentReference',
              link: { base: '/contracts', slug: 'parent_id' }
            },
            {
              label: t('type'),
              slug: 'endorsementType',
              text: 'endorsementTypeLabel',
              valueFormatter: formatContractType
            },
            { label: t('agreement_type'), slug: 'agreementTypeLabel' },
            {
              label: t('client'),
              slug: 'clientName',
              link: { base: '/enterprises', slug: 'clientId' }
            },
            { label: t('center'), slug: 'center' },
            { label: PlayArrow, slug: 'begin', valueFormatter: formatDate },
            {
              label: t('surface_difference'),
              slug: 'surface_difference',
              valueFormatter: formatSurface
            },
            { label: t('workforce_difference'), slug: 'workforce_difference' },
            {
              label: t('commitment_difference'),
              slug: 'commitment_difference',
              valueFormatter: formatCommitment
            },
            {
              label: t('amount_difference'),
              slug: 'amount_difference',
              valueFormatter: formatCurrency
            },
            { label: t('created_at'), slug: 'created_at', valueFormatter: formatDate }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(() => refreshList(true), property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {endorsements.length} / {total}
          </Typography>
        </Grid>
        {!isLast && <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />}
      </Grid>
    </Container>
  )
}
