import React, { useCallback, useEffect, useState } from 'react'
import { Container } from '@mui/system'
import { Box, Grid, Typography } from '@mui/material'
import { FiltersBox } from 'app/components/filters/filter-box'
import { ListPagination } from 'app/components/lists/list-pagination'
import { useList } from 'app/providers/list.provider'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useTranslation } from 'react-i18next'
import { Right } from 'api/models/rights'
import { List } from 'app/components/lists/list'
import { formatIconCheck } from 'app/utils/format'
import { useNavigate } from 'react-router-dom'

export const RightsView = (): React.JSX.Element => {
  const nav = useNavigate()
  const { t } = useTranslation()
  const {
    filtersList,
    isLast,
    total,
    orderBy,
    setOffset,
    setTotal,
    setIsLast,
    initFilters,
    handleSort,
    handleFilter
  } = useList()
  const { getRights, searchParams, setSearchParams } = useFetcher()
  const [rights, setRights] = useState<Right[]>([])
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([
      ['right_roles', 'roles'],
      ['right_resources', 'resources']
    ])
  )
  const refreshList = useCallback(
    async (reset: boolean) => {
      setListIsLoading(true)
      getRights
        .mutateAsync()
        .then((data) => {
          setOffset(data.actual)
          setIsLast(data.last)
          setTotal(data.total)
          setRights(reset ? data.items : (prev) => [...prev, ...data.items])
        })
        .finally(() => setListIsLoading(false))
    },
    [getRights, setIsLast, setOffset, setTotal]
  )

  useEffect(() => {
    initFilters(commonFilters).then(() => refreshList(true))
  }, [])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('rights_list')}
        </Typography>
      </Box>
      <Grid>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => handleFilter(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        ></FiltersBox>
      </Grid>
      <Grid>
        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {rights.length} / {total}
          </Typography>
        </Grid>
        <List
          items={rights}
          columns={[
            { label: t('role'), slug: 'role' },
            { label: t('resource'), slug: 'resource' },
            { label: t('access'), slug: 'isDisplay', valueFormatter: formatIconCheck },
            { label: t('details'), slug: 'isShow', valueFormatter: formatIconCheck },
            { label: t('add'), slug: 'isAdd', valueFormatter: formatIconCheck },
            { label: t('edit'), slug: 'isEdit', valueFormatter: formatIconCheck },
            { label: t('delete'), slug: 'isDelete', valueFormatter: formatIconCheck },
            { label: t('exportable'), slug: 'isExportable', valueFormatter: formatIconCheck },
            { label: t('regenerable'), slug: 'isRegenerable', valueFormatter: formatIconCheck },
            { label: t('all_centers'), slug: 'isAllCenters', valueFormatter: formatIconCheck }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          onClick={(right) => nav(`/rights/${right.id}`)}
          handleSort={(property) => handleSort(() => refreshList(true), property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {rights.length} / {total}
          </Typography>
        </Grid>
        {!isLast && <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />}
      </Grid>
    </Container>
  )
}
