export const BASE_URL = process.env.REACT_APP_API_URL
export const BASE_CLIENT_URL = process.env.REACT_APP_CLIENT_URL
export const FORGOTTEN_TOKEN = process.env.REACT_APP_FORGOTTEN_TOKEN

export const API = {
  // Documents
  DOCUMENT: '/documents/:id',
  DOCUMENT_INVALIDATE: '/documents/:id/invalidate',
  DOCUMENT_VALIDATE: '/documents/:id/validate',
  // Accounting
  GET_ACCOUNTING_CLIENTS: '/accountingclients',
  GET_ACCOUNTING_CLIENTS_EXPORT: '/accountingclients/export',
  GET_ACCOUNTING_CLIENTS_EXPORT_SAGE: '/accountingclients/exportsage',
  GET_ACCOUNTING_DOCUMENTS: '/accountingdocuments',
  GET_ACCOUNTING_PAYMENTS: '/accountingpayments',
  GET_ACCOUNTING_PAYMENTS_EXPORT: '/accountingpayments/export',
  GET_ACCOUNTING_VARIOUS_OPERATIONS: '/accountingvariousoperations',
  GET_ACCOUNTING_VARIOUS_OPERATIONS_EXPORT: '/accountingvariousoperations/export',
  GET_ACCOUNTING_MATCHING_TRANSFERS_EXCEPTIONS: '/matchingtransfersexception',
  GET_ACTIVITIES: '/:type/:id/activities',
  // Auth
  SIGN_IN: '/connection',
  FORGOTTEN_PASSWORD: '/forgotten-password',
  //Center Price
  CENTER_PRICES: '/centerprices',
  CENTER_PRICES_BLOCKED_PRICES: '/centerprices/:id/blocked-prices',
  //Companies
  GET_COMPANIES: '/companies',
  GET_COMPANY: '/companies/:id',
  GET_COMPANY_CENTERS: '/companies/:id/centers',
  GET_COMPANY_CONFIG: '/companies/:id/configuration',
  GET_COMPANY_BANK_ACCOUNT: '/companies/:id/bank-account',
  // Enterprises
  GET_ENTERPRISES: '/enterprises',
  GET_DUPLICATE_ENTERPRISES: '/enterprises/duplicate',
  GET_ENTERPRISE_CONTRACTS: '/enterprises/:id/contracts',
  GET_ENTERPRISE_DOCUMENTS: '/enterprises/:id/documents',
  GET_ENTERPRISE_EVENTS: '/enterprises/:id/events',
  GET_ENTERPRISE_MEMBERS: '/enterprises/:id/members',
  GET_ENTERPRISE_OPPORTUNITIES: '/enterprises/:id/opportunities',
  GET_ENTREPRISE_PRESCRIBER: '/enterprises/:id/prescribers/:prescriberId',
  GET_ENTERPRISE_SSIDS: '/enterprises/:id/ssids',
  GET_ENTERPRISE_SSIDS_USERS: '/enterprises/:id/ssids/:ssid/users',
  PUT_ENTERPRISE: '/enterprises',
  POST_ENTERPRISE: '/clients',
  POST_ENTERPRISE_MEMBER: '/enterprises/:id/members',
  POST_ENTERPRISE_MEMBER_LINK: '/enterprises/:id/members/link',
  POST_ENTERPRISE_OPPORTUNITIES: '/enterprises/:id/opportunities',
  POST_ENTERPRISE_PRESCRIBERS: '/enterprises/:id/prescribers',
  POST_ENTERPRISE_SSID: '/enterprises/:id/ssids',
  POST_ENTERPRISE_SSID_USER: '/enterprises/:id/ssids/:ssid/users',
  UPDATE_ENTERPRISE_SSID_USER: '/enterprises/:id/ssids/:ssid/users/:ssidUser',
  UPLOAD_ENTERPRISE_DOCUMENT: '/enterprises/:id/documents/upload',
  // Email Templates
  GET_EMAIL_TEMPLATES: '/email-templates',
  GET_EMAIL_TEMPLATE: '/email-templates/:id',
  PATCH_EMAIL_TEMPLATE: '/email-templates/:id',
  // Contracts
  CONTRACT_LINK: '/contracts/:id/link',
  CONTRACT_USERS: '/contracts/:id/users',
  CONTRACTS_CHANGE_SERVICE: '/contracts/:id/services/:contractServiceId/change-service',
  GET_CONTRACTS: '/contracts',
  GET_CONTRACT: '/contracts/:id',
  GET_CONTRACT_READ: '/contracts/:id',
  GET_CONTRACT_MAIN: '/contracts/:id/main',
  GET_CONTRACT_OPTION: '/contracts/:id/options',
  GET_CONTRACT_CONSUMPTION: '/contracts/:id/consumptions',
  GET_CONTRACT_ENDORSEMENTS: '/contracts/:id/endorsements',
  GET_CONTRACT_INVOICE: '/contracts/:id/invoices',
  GET_CONTRACT_INDEXING_ANNIVERSARY: '/contracts/:id/indexing',
  GET_CONTRACT_LITIGATIONS: '/contracts/:id/litigations',
  GET_CONTRACT_PAYMENTS: '/contracts/:id/payments',
  GET_CONTRACT_SELECT_OPTIONS: '/contracts/:id/select-options',
  POST_CONTRACT_ENDORSEMENTS: '/contracts/:id/endorsements',
  UPDATE_CONTRACT_OCCUPANT: '/contracts/:id/update-occupant',
  UPDATE_CONTRACT: '/contracts/:id',
  GET_CONTRACT_SUM: '/contracts/sum',
  GET_CONTRACT_EXPORT: '/contracts/export',
  GET_CONTRACTS_OPTIONS: '/contracts-options',
  GET_CONTRACTS_OPTIONS_SUM: '/contracts-options/sum',
  GET_CONTRACT_PDF: '/contracts/:id/pdf',
  GET_CONTRACTS_CONSUMPTIONS: '/contracts/consumptions',
  GET_CONTRACTS_CONSUMPTIONS_SUM: '/contracts/consumptions/sum',
  GET_CLIENT_CONTRACTS: '/clients/:id/contracts',
  POST_CONTRACT: '/contracts',
  PATCH_CONTRACT_ADMIN: '/contracts/:id/admin-update',
  PATCH_CONTRACT_AGREE: '/contracts/:id/agree',
  PATCH_CONTRACT_CANCEL: '/contracts/:id/cancel',
  PATCH_CONTRACT_LITIGATION: '/contracts/:id/litigation',
  PATCH_CONTRACT_VALIDATE: '/contracts/:id/validate',
  POST_CONTRACT_INVOICE_PARTIAL: '/contracts/:id/invoices/partial',
  POST_CONTRACT_SERVICE: '/contracts/:id/services',
  DELETE_CONTRACT_SERVICE: '/contracts/:id/services/:serviceId',
  STOP_CONTRACT_SERVICE: '/contracts/:id/services/:serviceId/stop',
  //Contact
  GET_CONTACTS: '/contacts',
  GET_CONTACTS_EXPORT: '/contacts/export',

  //Dashboard
  GET_DASHBOARD_CONTRACTS: '/dashboard/contracts',
  GET_DASHBOARD_OCCUPATIONS: '/dashboard/occupations',
  GET_DASHBOARD_VISITS: '/dashboard/visits',
  GET_DASHBOARD_NEXT_ENTRIES: '/dashboard/next_entries',
  GET_DASHBOARD_MEETINGS: '/dashboard/meetings',
  GET_DASHBOARD_OPEN_DESKTOPS: '/dashboard/open_desktops',
  GET_DASHBOARD_OTHER_INFORMATION: '/dashboard/other_information',
  // Filters
  GET_FILTERS: '/filters',
  GET_CLIENTS_FILTER: '/filter_clients',
  GET_CLIENT_CONSUMPTIONS: '/clients/:id/consumptions',
  GET_CENTER_FILTER: '/filter_centers',
  GET_CENTER_FILTER_SELECT: '/filter_centers/select',
  GET_CENTER_VISOR_GROUPS: '/centers/:id/visor-groups',
  GET_BUSINESS_PLAN: '/centers/bp',
  //Visor
  GET_CENTERS_VISORS: '/centers/visor',
  GET_STAFF_FILTER: '/filter_staffs',
  GET_WIFI_FILTER_STATIONS: '/filter_wifi/stations',
  GET_WIFI_FILTER_SSIDS: '/filter_wifi/ssid',
  GET_ENTERPRISE_MEMBERS_FILTER: '/enterprises/:id/filter_members',
  VISOR_ROOMS: '/centers/:id/visor-rooms',
  //Form Items
  GET_FORM_ITEMS: '/formitems',
  // Invoices
  GET_INVOICES: '/invoices',
  GET_INVOICES_INFORMATIONS: '/invoices/:id',
  GET_INVOICES_PDF: '/invoices/:id/pdf',
  GET_INVOICE_SUM: '/invoices/sum',
  GET_INVOICES_ACTIVITIES: '/invoices/:id/activities',
  GET_INVOICES_REGENERATE: '/invoices/:id/regeneratepdf',
  PATCH_INVOICES_ACCOUNT: '/invoices/:id/account',
  PATCH_INVOICES_UNACCOUNT: '/invoices/:id/unaccount',
  PATCH_INVOICES_BLOCK: '/invoices/:id/block',
  PATCH_INVOICES_UNBLOCK: '/invoices/:id/unblock',
  PATCH_INVOICES_LITIGATION: '/invoices/:id/litigation',
  GET_INVOICES_UNCOLLECTIBLE: '/invoices/:id/uncollectible',
  GET_INVOICES_MANUAL_REMINDER: '/invoices/:id/manual-reminder',
  GET_INVOICES_ROWS: '/invoices/:id/rows',
  GET_INVOICES_DISCOUNT: '/invoices/:id/discount',
  PATCH_INVOICES_LANGUAGE: '/invoices/:id/language',
  GET_INVOICES_MANUAL_BANK_DEBIT: '/invoices/:id/manualbankdebit',
  GET_INVOICES_ADD_PAYMENT: '/invoices/:id/addpayment',
  GET_INVOICES_LINK_PAYMENT: '/invoices/:id/payments/:payment/link_payment',
  GET_INVOICES_UNLINK_PAYMENT: '/invoices/:id/payments/:payment/unlink_payment',
  DELETE_INVOICES_UNLINK_CREDIT: '/invoices/:id/credits/:credit/unlink_credit',
  GET_INVOICES_CANCELLED_PAYMENT: '/invoices/:id/payments/:payment/cancelledgc',
  GET_INVOICES_PAYMENTS: '/invoices/:id/payments',
  GET_INVOICES_CREDITS: '/invoices/:id/credits',
  PATCH_INVOICES_DUE_DATE: '/invoices/:id/due-date',
  // Options
  GET_OPTIONS: '/options',
  // Centers
  GET_CENTERS: '/centers',
  CENTER: '/centers/:id',
  CLUSTERS: '/clusters',
  // Credits
  GET_CREDITS: '/credits',
  GET_CREDIT_SUM: '/credits/sum',
  GET_CREDIT_EXPORT: '/credits/export',
  GET_CREDIT_INFORMATION: '/credits/:id',
  GET_CREDIT_PDF: '/credits/:id/pdf',
  GET_CREDIT_REGENERATE_PDF: '/credits/:id/regeneratepdf',
  GET_CREDIT_INVOICE_SELECT: '/credits/:id/invoices-select',
  GET_CREDIT_APPLY_INVOICE: '/credits/:id/apply-on-invoice',
  GET_CREDIT_DECLARE_PAYMENT: '/credits/:id/declare-payment',
  GET_CREDIT_INVOICE: '/credits/:id/invoices',
  GET_CREDIT_PAYMENT: '/credits/:id/payments',
  PATCH_CREDIT_LANGUAGE: '/credits/:id/language',
  GET_CREDIT_ACTIVITIES: '/credits/:id/activities',
  // Crypt
  POST_ENCRYPT: '/cryptology/encrypt',
  POST_DECRYPT: '/cryptology/decrypt',
  //Service occupancy
  GET_SERVICES_AVAILABLE: '/services/available',
  GET_SERVICE_AVAILABILITY: '/services/:id/availability',
  GET_SERVICE_COMMITMENT_PRICE: '/services/:id/calculate-price',
  GET_SERVICES_AVAILABLE_DESKTOPS: '/services/available-desktops',
  GET_SERVICE_OCCUPANCY: '/main-services/occupancy',
  GET_SERVICE_OCCUPANCY_SUM: '/main-services/occupancy/sum',
  GET_SERVICE_OCCUPANCY_EXPORT: '/main-services/occupancy/export',
  GET_SERVICE_PRICING: '/services/:id/pricing',
  GET_SERVICE_CONSUMPTIONS_PRICING: '/services/:id/pricing-consumption',
  // Rights
  GET_RIGHTS: '/rights',
  //Discounts
  DISCOUNTS: '/discounts',
  DISCOUNT: '/discounts/:id',
  DISCOUNTS_EXIST: '/discounts/exist',
  // Discount grid
  GET_DISCOUNT_GRIDS: '/discount-grids',
  GET_DISCOUNT_GRID: '/discount-grids/:id',
  GET_DISCOUNT_GRID_READ: '/discountgrid/:id',
  // Discount grid center
  GET_DISCOUNT_GRIDS_CENTER: '/center-discount-grids',
  //Litigations
  CLOSE_LITIGATION: '/litigations/:id/close',
  GET_LITIGATIONS: '/litigations',
  GET_LITIGATION: '/litigations/:id',
  GET_LITIGATION_CONTRACTS: '/litigations/:id/contracts',
  GET_LITIGATION_INVOICES: '/litigations/:id/invoices',
  //Opportunities
  GET_OPPORTUNITIES: '/opportunities',
  GET_OPPORTUNITY: '/opportunities/:id',
  GET_OPPORTUNITY_CONTRACTS: '/opportunities/:id/contracts',
  GET_OPPORTUNITY_EVENTS: '/opportunities/:id/events-list',
  GET_OPPORTUNITY_SOLUTIONS: '/opportunities/:id/quotations',
  GET_OPPORTUNITY_USERS: '/opportunities/:id/users',
  PATCH_OPPORTUNITY: '/opportunities/:id',
  POST_OPPORTUNITIES_QUOTATION: '/opportunities/:id/quotation-create',
  GET_ACTUAL_PIPELINE: '/opportunities/pipeline',
  //Individuals
  GET_INDIVIDUALS: '/individuals',
  GET_INDIVIDUAL_ACCESS: '/individuals/:id/access',
  GET_INDIVIDUAL_CONSUMPTIONS: '/individuals/:id/consumptions',
  GET_INDIVIDUAL_DEVICES: '/individuals/:id/devices',
  UPDATE_INDIVIDUAL_DEVICES: '/individuals/devices/:id',
  GET_INDIVIDUAL_ENTERPRISES: '/individuals/:id/enterprises',
  GET_INDIVIDUAL_EVENTS: '/individuals/:id/events',
  UPDATE_INDIVIDUAL_CLIENTLINK: '/individuals/:id/clientlink/:clientId',
  GET_INDIVIDUAL_INVOICES: '/individuals/:id/invoices',
  PATCH_INDIVIDUAL: '/individuals',
  PATCH_INDIVIDUAL_ACCESS: '/individuals/:id/access',
  POST_INDIVIDUAL: '/individuals',
  POST_INDIVIDUAL_ACCESS: '/individuals/:id/access',
  PUT_INDIVIDUAL: '/individuals',
  // Checkouts
  GET_CHECKOUTS: '/checkouts',
  GET_CHECKOUT_SUM: '/checkouts/sum',
  // Mandates
  GET_MANDATES: '/paymentmethods',
  GET_MANDATE_PAYMENT_METHOD: '/paymentmethods/:id/paymentmethod',
  GET_MANDATE_BANK_ACCOUNT: '/paymentmethods/:id/bankaccount',
  GET_MANDATE_PAYMENT: '/paymentmethods/:id/payments',
  MANDATE_CANCELLED: '/paymentmethods/:id/cancelledgc',
  // Services
  MAIN_SERVICES: '/services-main',
  GET_MAIN_SERVICE: '/services-main/:id',
  UPDATE_MAIN_SERVICE: '/services-main/:id',
  GET_COMPUTED_PRICE: '/services-main/compute-price',
  GET_MAIN_SERVICES_SUM: '/services-main/sum',
  GET_OPTION_SERVICE: '/services-option/:id',
  UPDATE_OPTION_SERVICE: '/services-option/:id',
  GET_PARKING_SERVICES: '/services-parkings',
  GET_PARKING_SERVICE: '/services-parkings/:id',
  UPDATE_PARKING_SERVICE: '/services-parkings/:id',
  // Prescribers
  GET_PRESCRIBERS: '/prescribers',
  //Plannings
  GET_PLANNINGS_DESKTOPS: '/schedules/desktops',
  GET_PLANNINGS_DESKTOPS_FLOORS: '/schedules/desktops/floors',
  // Payments
  GET_PAYMENTS: '/payments',
  GET_PAYMENTS_SUM: '/payments/sum',
  GET_PAYMENT_READ: '/payments/:id',
  // PRICER
  GET_PRICER_INFORMATIONS: '/opportunities/:id/pricer-informations',
  GET_PRICER_SERVICES: '/opportunities/:id/pricer-services',
  // Configuration
  GET_CONFIGURATION_DISCOUNT_GRIDS: '/configuration/discount-grids',
  //Quotations
  GET_QUOTATIONS: '/quotations',
  GET_QUOTATION_SOLUTIONS: '/quotations/:id/solutions',
  GET_QUOTATION_DOWNLOAD: '/quotations/:id/download',
  PATCH_QUOTATION: '/quotations/:id/update',
  PATCH_QUOTATION_CANCEL: '/quotations/:id/cancel',
  PATCH_QUOTATION_SOLUTION: '/quotations/:id/solutions/:solutionId',
  PATCH_QUOTATION_SOLUTION_PRICE: '/quotations/:id/solutions/:solutionId/price',
  POST_QUOTATION_SEND: '/quotations/:id/send',
  POST_QUOTATION_SOLUTION_TRANSFORM: '/quotations/:id/solutions/:solutionId/transform',
  QUOTATION_SOLUTION_SELECT_COMMITMENTS: '/quotations/:id/solutions/:solutionId/commitments-select',
  //Consumptions
  GET_CONSUMPTIONS: '/consumptions',
  GET_CONSUMPTION: '/consumptions/:id',
  GET_CONSUMPTION_CONTRACT: '/consumption-contracts/:id',
  GET_CONSUMPTION_CONTRACT_CONSUMPTIONS: '/consumption-contracts/:id/consumptions',
  // Indexing
  GET_INDEXING: '/indexing',
  // Endorsements
  GET_ENDORSEMENTS: '/endorsements',
  ENDORSEMENT_SELECT_COMMITMENTS: '/endorsements/:id/commitments-select',
  ENDORSEMENT_SEND_TO_DIRECTION: '/endorsements/:id/send_to_direction',
  ENDORSEMENT_VALIDATE: '/endorsements/:id/validate',
  PATCH_ENDORSEMENT_SERVICE: '/endorsements/:id/add_service',
  // Client center
  GET_CLIENT_CENTER: '/clients/:id/client_center',
  PATCH_CLIENT_CENTER: '/clients/:id/client_center/:clientCenterId',
  PATCH_CLIENT_CENTER_MAIL: '/clients/:id/client_center/:clientCenterId/accouting_mail',
  // Client service
  GET_CLIENT_REQUEST: '/clientrequests/:id',
  GET_CLIENT_REQUESTS: '/clientrequests',
  GET_CLIENT_REQUESTS_SUM: '/clientrequests/sum',
  PATCH_CLIENT_REQUEST: '/clientrequests/:id',
  // Customer reservations
  GET_CUSTOMER_RESERVATIONS: '/customer_reservations',
  GET_CUSTOMER_RESERVATION: '/customer_reservations/:id',
  GET_CUSTOMER_RESERVATION_CONTRACTS: '/customer_reservations/:id/contracts-select',
  GET_CUSTOMER_RESERVATION_INDIVIDUALS: '/customer_reservations/:id/individuals-select',
  GET_CUSTOMER_RESERVATION_SERVICES: '/customer_reservations/:id/services',
  GET_CUSTOMER_RESERVATIONS_SUM: '/customer_reservations/sum',
  PATCH_CUSTOMER_RESERVATION_CANCEL: '/customer_reservations/:id/cancel',
  PATCH_CUSTOMER_RESERVATION_CONFIRM: '/customer_reservations/:id/confirm',
  PATCH_CUSTOMER_RESERVATION_UPDATE: '/customer_reservations/:id/update',
  POST_CUSTOMER_RESERVATIONS: '/customer_reservations',
  POST_CUSTOMER_RESERVATION_SERVICE: '/customer_reservations/:id/services/:serviceId',
  POST_CUSTOMER_RESERVATION_LINK_TO_ACCESS: '/customer_reservations/:id/link-to-access',
  //Events
  GET_EVENTS: '/events',
  POST_EVENT: '/events',
  PATCH_EVENT: '/events/:id',
  //Happening
  GET_HAPPENINGS: '/happenings',
  POST_HAPPENING: '/happenings',
  GET_HAPPENINGS_PARTNERS: '/happenings/partner',
  GET_HAPPENINGS_DETAILS: '/happenings/:id',
  PATCH_HAPPENING: '/happenings/:id',
  POST_HAPPENINGS_PARTNERS: '/happenings/partner',

  // Floor plans
  GET_FLOOR_PLANS: '/floorplans',
  GET_LIST_FLOORS: '/floorplans/:center/listfloors',
  GET_FILTER_CONTRACTS: '/filter_activecontracts',
  GET_CONTRACTS_FLOORS: '/floorplans/:floorplan/listactivecontracts',
  // Activities
  POST_ACTIVITIES: '/activities',
  // Clients
  GET_CLIENT_CONTRACT_CONSUMPTIONS: '/clients/:id/contracts-consumptions',
  // Monitoring
  GET_MESSAGES: '/messages',
  GET_AUTHENTIFICATION_RADIUS: '/authentifications_client',
  // Notifications
  GET_NOTIFICATIONS: '/notifications',
  UPDATE_NOTIFICATION: '/notifications/:id',
  //ToS
  TERMS_OF_SALES: '/term-of-sales',
  TERM_OF_SALES: '/term-of-sales/:id',
  //Sources
  GET_SOURCES: '/sources',
  GET_SOURCE_ACQUISITION_COSTS: '/acquisition_costs',
  UPDATE_SOURCE_ACQUISITION_COST: '/acquisition_costs/:id',
  //Staffs
  STAFFS: '/staff',
  STAFF: '/staff/:id',
  UPDATE_STAFF_LANGUAGE: '/staff/:id/language',
  STAFF_PHONE_SYSTEM: '/staff/:id/phone-system-staff',
  // Stats
  GET_STATS_ACQUISITIONS: '/stats/acquisitions',
  GET_STATS_CLIENT_REQUEST: '/stats/clientrequest',
  GET_STATS_INVENTORY: '/stats/inventory',
  GET_STATS_OCCUPATION: '/stats/occupation',
  GET_STATS_PERFORMANCE: '/stats/performances',
  GET_STATS_SURFACE: '/stats/pricepersurface',
  GET_STATS_CA: '/stats/revenue',
  //Wifi
  GET_WIFI_CLIENTS: '/wifi/clients',
  GET_WIFI_LOGS: '/wifi/logs',
  // Tertiaries
  TERTIARIES: '/tertiaries',
  //Typologies
  TYPOLOGIES: '/typologies',
  // Commitments
  COMMITMENTS_LIST: '/commitments',
  // Center discounts
  GET_CENTER_DISCOUNTS: '/center-discounts',
  GET_CENTER_DISCOUNTS_NOT_ACTIVE: '/center-discounts/not-active'
}
