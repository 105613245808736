import { TitleComponent } from 'app/components/titles/title.component'
import { Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useApp } from 'app/providers/app.provider'
import { Control, useFieldArray } from 'react-hook-form'
import { ControlledTextField } from 'app/components/form/controlled-textfield.component'
import { useTranslation } from 'react-i18next'

interface IProps {
  control: Control<any>
  service: any
}

export default function LanguageComponent({ control, service }: IProps) {
  const { t } = useTranslation()
  const { getLanguages } = useApp()
  const { fields: savedLabels, replace } = useFieldArray({
    name: 'savedLabels',
    control: control
  })

  useEffect(() => {
    let data = [] as { language: number; label: string }[]
    getLanguages().map((language) => {
      const value = service.savedLabels?.find(
        (savedLabel: any) => savedLabel.language.id == language.id
      )
      data.push({
        label: value ? value.label ?? '' : '',
        language: language.id
      })
    })
    replace(data)
  }, [])

  return (
    <>
      <TitleComponent
        text={t('wording') + ' *'}
        variant={'h3'}
        paddingTop={0}
        paddingLeft={12}
        marginBottom={4}
      />
      <Grid container spacing={5} marginBottom={5}>
        {savedLabels.map((savedLabel: any, index) => {
          const language = getLanguages().find((language) => language.id == savedLabel.language)
          return (
            <Grid key={savedLabel.id} container item xs={12} sm={6}>
              <Grid item xs={12} sm={4} margin={'auto'}>
                <Typography>{language?.label}</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <ControlledTextField
                  key={savedLabel.id}
                  name={`savedLabels.${index}.label`}
                  control={control}
                />
              </Grid>
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}
