import { TitleComponent } from 'app/components/titles/title.component'
import {
  Box,
  Checkbox,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import React from 'react'
import { FormItemValue, MainService, ParkingServiceBody } from 'api/models'
import { useTranslation } from 'react-i18next'
import { UseFormReturn } from 'react-hook-form'
import { ControlledDatePicker } from 'app/components/form/controlled-datepicker.component'
import { Euro } from '@mui/icons-material'

interface IProps {
  mainService: MainService
  typologies: FormItemValue[]
  methods: UseFormReturn<ParkingServiceBody>
}

export default function ParkingMainInformationsComponent({
  methods,
  mainService,
  typologies
}: IProps) {
  const { t } = useTranslation()

  return (
    <>
      <TitleComponent
        text={t('main_informations')}
        variant={'h3'}
        paddingTop={0}
        paddingLeft={12}
        marginBottom={4}
      />
      <Grid container spacing={5} marginBottom={5}>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={4} margin={'auto'}>
            <Box sx={{ display: 'flex', gap: 5, flexWrap: 'wrap' }}>
              <Typography>{t('monthly_price')} *</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              size={'small'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Euro fontSize={'small'} />
                  </InputAdornment>
                ),
                inputProps: {
                  step: 0.01
                }
              }}
              type="number"
              variant="outlined"
              defaultValue={mainService.price}
              {...methods.register('price', {
                setValueAs: (value) => (value && value > 0 ? parseFloat(value) : undefined)
              })}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}></Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={4} margin={'auto'}>
            <Typography>{t('center')}</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              disabled
              fullWidth
              size={'small'}
              variant="outlined"
              defaultValue={mainService.centerName}
              InputProps={{
                readOnly: true
              }}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={4} margin={'auto'}>
            <Typography>{t('floor')}</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              size={'small'}
              variant="outlined"
              type="number"
              defaultValue={mainService.floor}
              {...methods.register('floor')}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={4} margin={'auto'}>
            <Typography>{t('capacity')}</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              size={'small'}
              variant="outlined"
              type="number"
              defaultValue={mainService.maxCapacity}
              {...methods.register('maxCapacity')}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={4} margin={'auto'}>
            <Typography>{t('building')}</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              size={'small'}
              variant="outlined"
              defaultValue={mainService.building}
              {...methods.register('building')}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={4} margin={'auto'}>
            <Typography>{t('begin')} *</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <ControlledDatePicker name={'begin'} control={methods.control} />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={4} margin={'auto'}>
            <Typography>{t('end')}</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <ControlledDatePicker name={'end'} control={methods.control} />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={4} margin={'auto'}>
            <Typography>{t('typology')}</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Select
              fullWidth
              size={'small'}
              {...methods.register('typology')}
              value={methods.getValues().typology}
            >
              {typologies.map(
                (typology: any) =>
                  typology.id !== 0 && (
                    <MenuItem key={typology.id} value={String(typology.id)}>
                      {typology.label}
                    </MenuItem>
                  )
              )}
            </Select>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={4} margin={'auto'}>
            <Typography>{t('available_online')}</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Checkbox
              defaultChecked={mainService.isOnline}
              size={'small'}
              {...methods.register('isOnline')}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
