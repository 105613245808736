import React, { useCallback, useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { List } from 'app/components/lists/list'
import { useFetcher } from 'app/providers/fetcher.provider'
import { ListPagination } from 'app/components/lists/list-pagination'
import { useList } from 'app/providers/list.provider'
import { FiltersBox } from 'app/components/filters/filter-box'
import { SearchCenterInput } from 'app/components/filters/search-center-input'
import { Box, Container } from '@mui/system'
import { formatCurrency, formatDate } from 'app/utils/format'

export const ParkingServicesView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { getParkingServices, setSearchParams, searchParams } = useFetcher()
  const {
    filtersList,
    isLast,
    total,
    orderBy,
    setOffset,
    setTotal,
    setIsLast,
    initFilters,
    handleSort,
    handleFilter
  } = useList()
  const [parkingServices, setParkingServices] = useState<any[]>([])
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([['parking_services_expired', 'expired']])
  )
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)

  const refreshList = useCallback(
    async (reset: boolean = true) => {
      setListIsLoading(true)
      getParkingServices
        .mutateAsync()
        .then((data) => {
          setIsLast(data.last)
          setOffset(data.actual)
          setTotal(data.total)
          setParkingServices(reset ? data.items : (prev) => [...prev, ...data.items])
        })
        .finally(() => setListIsLoading(false))
    },
    [getParkingServices, setIsLast, setOffset, setTotal]
  )

  useEffect(() => {
    initFilters(commonFilters).then(() => refreshList(true))
  }, [])

  return (
    <Container>
      <Grid item xs={12}>
        <Box marginBottom="2rem">
          <Typography variant="h2" gutterBottom display="inline">
            {t('parking')}
          </Typography>
        </Box>
        <Grid>
          <FiltersBox
            filters={filtersList}
            handleFilters={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          >
            <SearchCenterInput
              slug="center"
              onChange={() => handleFilter(refreshList, true)}
              setSearchParams={setSearchParams}
              searchParams={searchParams}
            />
          </FiltersBox>
        </Grid>
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom>
            {parkingServices.length} / {total}
          </Typography>
        </Grid>
        <List
          items={parkingServices}
          columns={[
            { label: t('label'), slug: 'label', link: { base: '/services/parking', slug: 'id' } },
            { label: t('center'), slug: 'center' },
            { label: t('typology'), slug: 'typology' },
            { label: t('floor'), slug: 'floor' },
            { label: t('capacity'), slug: 'capacity' },
            { label: t('building'), slug: 'building' },
            { label: t('monthly_price'), slug: 'price', valueFormatter: formatCurrency },
            { label: t('begin'), slug: 'begin', valueFormatter: formatDate },
            { label: t('end'), slug: 'end', valueFormatter: formatDate }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(refreshList, property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {parkingServices.length} / {total}
          </Typography>
        </Grid>
        {!isLast && <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />}
      </Grid>
    </Container>
  )
}
