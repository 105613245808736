import { type Ctx, downloader, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import {
  ContractSum,
  type ContractList,
  ContractsOptionsList,
  ContractsOptionsSum,
  ContractsConsumptionsList,
  ContractUsers,
  ContractServiceLinkUser,
  ContractsConsumptionsSum,
  ClientContractList,
  ClientContractParams,
  Contract,
  ContractMainList,
  ContractOptionList,
  ContractConsumptionList,
  ContractInvoiceList,
  ContractIndexingAnniversaryList,
  IdReturn,
  EndorsementContract,
  ContractUpdate
} from 'api/models'
import urlHelper from 'app/helpers/url.helper'
import { CancelContractProps, UpdateAdminContractProps } from 'api/models/forms/contract'
import { EndorsementUpdateData } from 'api/models/forms/endorsement'

export const contractsRouter = {
  list: async ({ token }: Ctx, params: string) =>
    fetcher<ContractList>({
      url: `${BASE_URL}${API.GET_CONTRACTS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getSum: async ({ token }: Ctx, params: string) =>
    fetcher<ContractSum>({
      url: `${BASE_URL}${API.GET_CONTRACT_SUM}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getExport: async ({ token }: Ctx, params: string) =>
    downloader(
      {
        url: `${BASE_URL}${API.GET_CONTRACT_EXPORT}${formatParams(params)}`,
        method: 'GET',
        token: token
      },
      'contracts.xlsx'
    ),
  listOptions: async ({ token }: Ctx, params: string) =>
    fetcher<ContractsOptionsList>({
      url: `${BASE_URL}${API.GET_CONTRACTS_OPTIONS}${formatParams(params)}`,
      method: 'GET',
      token
    }),
  getOptionsSum: async ({ token }: Ctx, params: string) =>
    fetcher<ContractsOptionsSum>({
      url: `${BASE_URL}${API.GET_CONTRACTS_OPTIONS_SUM}${formatParams(params)}`,
      method: 'GET',
      token
    }),
  listConsumptions: async ({ token }: Ctx, params: string) =>
    fetcher<ContractsConsumptionsList>({
      url: `${BASE_URL}${API.GET_CONTRACTS_CONSUMPTIONS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getConsumptionsSum: async ({ token }: Ctx, params: string) =>
    fetcher<ContractsConsumptionsSum>({
      url: `${BASE_URL}${API.GET_CONTRACTS_CONSUMPTIONS_SUM}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  listForClient: async ({ token }: Ctx, data: ClientContractParams) =>
    fetcher<ClientContractList>({
      url: `${BASE_URL}${urlHelper(API.GET_CLIENT_CONTRACTS, { id: data.clientId })}?center=${
        data.centerId
      }&individual=${data.individualId}`,
      method: 'GET',
      token: token
    }),
  create: async ({ token }: Ctx, data: any) =>
    fetcher<IdReturn>({
      url: `${BASE_URL}${API.POST_CONTRACT}`,
      method: 'POST',
      token: token,
      body: data
    }),
  createService: async ({ token }: Ctx, id: number, params: any) =>
    fetcher<IdReturn>({
      url: `${BASE_URL}${urlHelper(API.POST_CONTRACT_SERVICE, { id: id })}`,
      method: 'POST',
      token: token,
      body: params
    }),
  getRead: async ({ token }: Ctx, id: number) =>
    fetcher<Contract>({
      url: `${BASE_URL}${urlHelper(API.GET_CONTRACT, { id: id })}`,
      method: 'GET',
      token: token
    }),
  getMain: async ({ token }: Ctx, id: number) =>
    fetcher<ContractMainList>({
      url: `${BASE_URL}${urlHelper(API.GET_CONTRACT_MAIN, { id: id })}`,
      method: 'GET',
      token: token
    }),
  updateOccupant: async (
    { token }: Ctx,
    params: { id: number; occupants: { [key: string]: number } }
  ) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.UPDATE_CONTRACT_OCCUPANT, params)}`,
      method: 'PATCH',
      token: token,
      body: params.occupants
    }),
  getOption: async ({ token }: Ctx, id: number) =>
    fetcher<ContractOptionList>({
      url: `${BASE_URL}${urlHelper(API.GET_CONTRACT_OPTION, { id: id })}`,
      method: 'GET',
      token: token
    }),
  getConsumption: async ({ token }: Ctx, id: number) =>
    fetcher<ContractConsumptionList>({
      url: `${BASE_URL}${urlHelper(API.GET_CONTRACT_CONSUMPTION, { id: id })}`,
      method: 'GET',
      token: token
    }),
  getInvoice: async ({ token }: Ctx, id: number) =>
    fetcher<ContractInvoiceList>({
      url: `${BASE_URL}${urlHelper(API.GET_CONTRACT_INVOICE, { id: id })}`,
      method: 'GET',
      token: token
    }),
  getIndexingAnniversary: async ({ token }: Ctx, id: number) =>
    fetcher<ContractIndexingAnniversaryList>({
      url: `${BASE_URL}${urlHelper(API.GET_CONTRACT_INDEXING_ANNIVERSARY, { id: id })}`,
      method: 'GET',
      token: token
    }),
  getUsers: async ({ token }: Ctx, id: number) =>
    fetcher<ContractUsers>({
      url: `${BASE_URL}${urlHelper(API.CONTRACT_USERS, { id: id })}`,
      method: 'GET',
      token: token
    }),
  linkServiceUser: async ({ token }: Ctx, id: number, data: ContractServiceLinkUser) =>
    fetcher<IdReturn>({
      url: `${BASE_URL}${urlHelper(API.CONTRACT_LINK, { id: id })}`,
      method: 'POST',
      token: token,
      body: data
    }),
  getPayments: async ({ token }: Ctx, id: number) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.GET_CONTRACT_PAYMENTS, { id: id })}`,
      method: 'GET',
      token: token
    }),
  getLitigations: async ({ token }: Ctx, id: number) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.GET_CONTRACT_LITIGATIONS, { id: id })}`,
      method: 'GET',
      token: token
    }),
  deleteService: async ({ token }: Ctx, contractId: number, serviceId: number) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.DELETE_CONTRACT_SERVICE, {
        id: contractId,
        serviceId: serviceId
      })}`,
      method: 'DELETE',
      token: token
    }),
  download: async ({ token }: Ctx, contractId: number, contractRef: string) =>
    downloader(
      {
        url: `${BASE_URL}${urlHelper(API.GET_CONTRACT_PDF, {
          id: contractId
        })}`,
        method: 'GET',
        token: token
      },
      `${contractRef}.pdf`,
      'pdf',
      'application/pdf'
    ),
  validate: async ({ token }: Ctx, contractId: number) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.PATCH_CONTRACT_VALIDATE, {
        id: contractId
      })}`,
      method: 'PATCH',
      token: token
    }),
  agree: async ({ token }: Ctx, contractId: number) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.PATCH_CONTRACT_AGREE, {
        id: contractId
      })}`,
      method: 'PATCH',
      token: token
    }),
  cancel: async ({ token }: Ctx, contractId: number, data: CancelContractProps) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.PATCH_CONTRACT_CANCEL, {
        id: contractId
      })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  adminUpdate: async ({ token }: Ctx, contractId: number, data: UpdateAdminContractProps) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.PATCH_CONTRACT_ADMIN, {
        id: contractId
      })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  postLitigation: async ({ token }: Ctx, contractId: number, reference: string) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.PATCH_CONTRACT_LITIGATION, {
        id: contractId
      })}`,
      method: 'POST',
      token: token,
      body: { reference }
    }),
  createEndorsement: async ({ token }: Ctx, contractId: number, date: string) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.POST_CONTRACT_ENDORSEMENTS, {
        id: contractId
      })}`,
      method: 'POST',
      token: token,
      body: {
        date
      }
    }),
  updateEndorsement: async ({ token }: Ctx, contractId: number, data: EndorsementUpdateData) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.POST_CONTRACT_ENDORSEMENTS, {
        id: contractId
      })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  getEndorsements: async ({ token }: Ctx, contractId: number) =>
    fetcher<EndorsementContract>({
      url: `${BASE_URL}${urlHelper(API.GET_CONTRACT_ENDORSEMENTS, {
        id: contractId
      })}`,
      method: 'GET',
      token: token
    }),
  getSelectOptions: async ({ token }: Ctx, contractId: number, serviceTypeReference: string) =>
    fetcher<EndorsementContract>({
      url: `${BASE_URL}${urlHelper(API.GET_CONTRACT_SELECT_OPTIONS, {
        id: contractId
      })}?serviceTypeReference=${serviceTypeReference}`,
      method: 'GET',
      token: token
    }),
  stopService: async ({ token }: Ctx, contractId: number, serviceId: number, end: string) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.STOP_CONTRACT_SERVICE, {
        id: contractId,
        serviceId: serviceId
      })}`,
      method: 'PATCH',
      token: token,
      body: {
        end
      }
    }),
  changeService: async (
    { token }: Ctx,
    id: string,
    contractServiceId: number,
    data: {
      contractServiceId: number
      serviceId: number
    }
  ) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.CONTRACTS_CHANGE_SERVICE, {
        id,
        contractServiceId
      })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  updateSignatoryAndLanguage: async ({ token }: Ctx, id: number, data: ContractUpdate) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.UPDATE_CONTRACT, { id: id })}`,
      method: 'PATCH',
      token: token,
      body: data
    })
}
