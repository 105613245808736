import * as React from 'react'
import {
  Dispatch,
  ReactNode,
  RefObject,
  SetStateAction,
  useCallback,
  useMemo,
  useState
} from 'react'
import { Button, CircularProgress, Stack } from '@mui/material'
import { Dialog, DialogRef } from 'app/components/dialog/dialog.component'
import { CheckCircle, Error, Info, SvgIconComponent, Warning } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

export type Confirm = {
  content: string | ReactNode
  variant?: 'success' | 'error' | 'warning' | 'info' | 'primary' | 'custom'
  onConfirm: () => Promise<void>
  onCancel?: () => Promise<void>
  title?: string
  confirmButtonLabel?: string
}

const ConfirmIcon: Map<Confirm['variant'], SvgIconComponent | null> = new Map([
  ['warning', Warning],
  ['success', CheckCircle],
  ['info', Info],
  ['error', Error],
  ['primary', Info],
  ['custom', null]
])

interface IConfirmDialogProps {
  confirmRef: RefObject<DialogRef>
  confirmData: Confirm | null
  setConfirmData: Dispatch<SetStateAction<Confirm | null>>
}

export const ConfirmDialog = function ({
  confirmRef,
  confirmData,
  setConfirmData
}: IConfirmDialogProps) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleConfirmClose = useCallback(async () => {
    if (confirmData?.onCancel) {
      await confirmData?.onCancel()
    }
    confirmRef?.current?.close()
    setConfirmData(null)
  }, [confirmRef, setConfirmData])

  const handleConfirm = useCallback(async () => {
    setIsLoading(true)
    if (confirmData?.onConfirm) {
      await confirmData?.onConfirm()
    }
    setIsLoading(false)
    confirmRef?.current?.close()
    setConfirmData(null)
  }, [handleConfirmClose, confirmData, setIsLoading])

  const { title, actions } = useMemo(() => {
    let Icon = ConfirmIcon.get(confirmData?.variant ?? 'custom')

    const _title = (
      <Stack direction={'row'} gap={3} alignItems={'center'}>
        {Icon && (
          <Icon color={confirmData?.variant !== 'custom' ? confirmData?.variant : undefined} />
        )}
        {t(confirmData?.title ?? '')}
      </Stack>
    )

    const _actions = (
      <>
        <Button
          variant={'outlined'}
          disabled={isLoading}
          onClick={handleConfirmClose}
          color={confirmData?.variant !== 'custom' ? confirmData?.variant : 'primary'}
        >
          {t('cancel')}
        </Button>
        <Button
          variant={'contained'}
          disabled={isLoading}
          onClick={handleConfirm}
          color={confirmData?.variant !== 'custom' ? confirmData?.variant : 'primary'}
        >
          {t(confirmData?.confirmButtonLabel ?? 'confirm')}
        </Button>
      </>
    )

    return { title: _title, actions: _actions }
  }, [t, confirmData, isLoading])

  return (
    <Dialog ref={confirmRef} title={title} actions={actions}>
      <Stack alignItems={'center'}>{isLoading ? <CircularProgress /> : confirmData?.content}</Stack>
    </Dialog>
  )
}
