import { Skeleton } from '@mui/material'

export const RectangularSkeleton = () => {
  return (
    <Skeleton
      variant="rectangular"
      width={200}
      height={46}
      animation="wave"
      sx={{ borderRadius: '4px' }}
    />
  )
}
