import { useCallback, useMemo, useState } from 'react'
import { FormControl } from '@mui/material'
import { DateRange, DateRangePicker, SingleInputDateRangeField } from '@mui/x-date-pickers-pro'
import dayjs, { Dayjs } from 'dayjs'
import { useTranslation } from 'react-i18next'
import { SetURLSearchParams } from 'react-router-dom'

interface IRangePickerProps {
  label: string
  slug: string
  onChange?: () => void
  setSearchParams: SetURLSearchParams
  searchParams: URLSearchParams
  valueLabel?: string
  slugs?: { begin: string; end: string }
  isNoDate?: boolean
}

export function RangePicker({
  label,
  slug,
  onChange,
  searchParams,
  setSearchParams,
  valueLabel,
  slugs,
  isNoDate = true
}: IRangePickerProps) {
  const { t } = useTranslation()

  // TODO: Add translation on shortcuts labels
  let itemsRange = useMemo(() => {
    let _items: (
      | { label: string; getValue: () => [dayjs.Dayjs, dayjs.Dayjs] }
      | { label: string; getValue: () => [any, any] }
    )[] = [
      {
        label: "Aujourd'hui",
        getValue: () => {
          const now = dayjs()
          return [now, now]
        }
      },
      {
        label: 'Le mois prochain',
        getValue: () => {
          const now = dayjs()
          const nextMonth = now.add(1, 'month')
          return [nextMonth.startOf('month'), nextMonth.endOf('month')]
        }
      },
      {
        label: 'Le mois en cours',
        getValue: () => {
          const now = dayjs()
          return [now.startOf('month'), now.endOf('month')]
        }
      },
      {
        label: 'Le mois dernier',
        getValue: () => {
          const now = dayjs()
          return [
            now.subtract(1, 'month').startOf('month'),
            now.subtract(1, 'month').endOf('month')
          ]
        }
      },
      {
        label: 'Les 3 derniers mois',
        getValue: () => {
          const now = dayjs()
          return [now.subtract(3, 'month'), now]
        }
      },
      {
        label: 'Les 6 derniers mois',
        getValue: () => {
          const now = dayjs()
          return [now.subtract(6, 'month'), now]
        }
      },
      {
        label: 'Les 12 derniers mois',
        getValue: () => {
          const now = dayjs()
          return [now.subtract(12, 'months').startOf('month'), now.endOf('month')]
        }
      },
      {
        label: "L'année en cours",
        getValue: () => {
          const now = dayjs()
          return [now.startOf('year'), now.endOf('year')]
        }
      },
      {
        label: "L'année prochaine",
        getValue: () => {
          const now = dayjs()
          return [now, now.add(1, 'year')]
        }
      },
      {
        label: "L'année précédente",
        getValue: () => {
          const now = dayjs()
          return [now.subtract(1, 'year').startOf('year'), now.subtract(1, 'year').endOf('year')]
        }
      }
    ]
    if (isNoDate) {
      _items.push({
        label: 'Aucune date',
        getValue: () => {
          return [null, null]
        }
      })
    }
    return _items
  }, [isNoDate])

  const defaultValue = useCallback((): DateRange<Dayjs> => {
    let _begin = slugs?.begin ?? `${slug}_begin`
    let _end = slugs?.end ?? `${slug}_end`

    const [startParam, endParam] = [searchParams.get(_begin), searchParams.get(_end)]

    if (startParam && endParam) {
      return [dayjs(startParam), dayjs(endParam)]
    } else if (valueLabel) {
      const _itemRange = itemsRange.find((item) => item.label === valueLabel)
      if (_itemRange) {
        const value = _itemRange.getValue()
        searchParams.append(_begin, value[0].format('YYYY-MM-DD'))
        searchParams.append(_end, value[1].format('YYYY-MM-DD'))
        return [dayjs(value[0]), dayjs(value[1])]
      }
    }

    return [null, null]
  }, [])

  const [value, setValue] = useState<DateRange<Dayjs>>(defaultValue())

  const handleChange = useCallback(
    (newValue: DateRange<Dayjs>) => {
      const [start, end] = newValue

      setValue(newValue)
      setSearchParams((searchParams) => {
        if (start && end) {
          searchParams.set(slugs?.begin ?? `${slug}_begin`, start.format('YYYY-MM-DD'))
          searchParams.set(slugs?.end ?? `${slug}_end`, end.format('YYYY-MM-DD'))
        } else {
          searchParams.delete(slugs?.begin ?? `${slug}_begin`)
          searchParams.delete(slugs?.end ?? `${slug}_end`)
        }
        return searchParams
      })

      if (onChange) {
        onChange()
      }
    },
    [onChange, setSearchParams, searchParams, slug, slugs]
  )

  return (
    <FormControl sx={{ width: 200 }}>
      <DateRangePicker
        label={t(label)}
        slots={{ field: SingleInputDateRangeField }}
        value={value}
        onChange={handleChange}
        calendars={1}
        slotProps={{
          shortcuts: {
            items: itemsRange
          },
          textField: {
            size: 'small' // Add size='small' to the slotProps
          }
        }}
        localeText={{ start: 'Début', end: 'Fin' }}
      />
    </FormControl>
  )
}
